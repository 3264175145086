import client, { parseItem, handleError } from '../axios-config'

const controllerPrefix = '/noww'

const getDashboard = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/dashboard`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getEmailSuppression = async function (customerId) {
  try {
    const response = await client.get(`${controllerPrefix}/getEmailSuppression/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getGlobalSuppression = async function (customerId) {
  try {
    const response = await client.get(`${controllerPrefix}/getGlobalSuppression/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addEmailSuppression = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/addEmailSuppression/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addGlobalSuppression = async function (customerId) {
  try {
    const response = await client.post(`${controllerPrefix}/addGlobalSuppression/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const emailSuppressionCodeCount = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/emailSuppressionCodeCount/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const globalSuppressionCodeCount = async function (customerId) {
  try {
    const response = await client.post(`${controllerPrefix}/globalSuppressionCodeCount/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteEmailSuppressionRecords = async function (suppressionId) {
  try {
    const response = await client.post(`${controllerPrefix}/deleteEmailSuppressionRecords/${suppressionId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteGlobalSuppressionRecords = async function (customerId) {
  try {
    const response = await client.post(`${controllerPrefix}/deleteGlobalSuppressionRecords/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const emailSuppressionBulkInsertFromTable = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/emailSuppressionBulkInsertFromTable/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const globalSuppressionBulkInsertFromTable = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/globalSuppressionBulkInsertFromTable/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getEmailSuppressionSummary = async function (suppressionId) {
  try {
    const response = await client.get(`${controllerPrefix}/getEmailSuppressionSummary/${suppressionId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getGlobalSuppressionSummary = async function (customerId) {
  try {
    const response = await client.get(`${controllerPrefix}/getGlobalSuppressionSummary/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTargetData = async function (customerId) {
  try {
    const response = await client.get(`${controllerPrefix}/getTargetData/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTDEmailDownloadCheck = async function (customerId) {
  try {
    const response = await client.get(`${controllerPrefix}/getTDEmailDownloadCheck/${customerId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTargetDataHeader = async function (suppressionId) {
  try {
    const response = await client.get(`${controllerPrefix}/getTargetDataHeader/${suppressionId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addTargetData = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/addTargetData/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const targetDataCodeCount = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/targetDataCodeCount/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTargetDataSummary = async function (suppressionId) {
  try {
    const response = await client.get(`${controllerPrefix}/getTargetDataSummary/${suppressionId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateTargetData = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/updateTargetData/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const importUpdateMatch = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/targetDataImportUpdate/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const targetdDataUploadMatch = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/targetDataUpdateMatch`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDrLookup = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/drLookup/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDrLookupDetails = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/drLookupDetails/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const drLookupAddToOmit = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/drLookupAddToOmit/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const drLookupRemoveFromOmit = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/drLookupRemoveFromOmit/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDrLookupFileTypes = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/drLookupFileTypes/`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDrLookupSpecialties = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/drLookupSpecialties/`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDrLookupStates = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/drLookupStates/`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getFileTypes = async function (tenantCrmId) {
  try {
    const response = await client.get(tenantCrmId ? `${controllerPrefix}/fileTypes/${tenantCrmId}` : `${controllerPrefix}/fileTypes`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUnassignedTAs = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/ta/unassigned/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setCampaignTA = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/ta/campaign`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const unassignCampaignTA = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/ta/campaign/unassign`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOrders = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/processOrder`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOmitCodeFileTypes = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/omitCodesFileType`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOmitCodesStart = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/getOmitCodesStart/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addOmitID = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/addOmitID/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getDownloadList = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/getProcessedDownloadList/`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

// GA functions
const GetDomainStatsLastXDays = async function (lastXDays) {
  try {
    const response = await client.get(`${controllerPrefix}/DomainStatsLastXDays`, {
      params: {
        lastXDays: lastXDays
      }
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const GetSendingDomainOverview = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/SendingDomainOverview/`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSelectDeviceSize = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/device-size`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

interface InsertDeviceSizeParams {
  device: string;
  width: number;
  height: number;
}

const insertDeviceSize = async function (params: InsertDeviceSizeParams) {
  try {
    const response = await client.post(`${controllerPrefix}/device-size`, params)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

interface UpdateDeviceSizeParams {
  id: number;
  device: string;
  width: number;
  height: number;
}

const updateDeviceSize = async function (params: UpdateDeviceSizeParams) {
  try {
    const response = await client.put(`${controllerPrefix}/device-size`, params)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

interface DeleteDeviceSizeParams {
  id: number;
}

const deleteDeviceSize = async function (params: DeleteDeviceSizeParams) {
  try {
    const response = await client.delete(`${controllerPrefix}/device-size/${params.id}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const nowwService = {
  addTargetData,
  addEmailSuppression,
  addGlobalSuppression,
  drLookupAddToOmit,
  drLookupRemoveFromOmit,
  targetDataCodeCount,
  updateTargetData,
  getTargetDataSummary,
  getDashboard,
  deleteEmailSuppressionRecords,
  deleteGlobalSuppressionRecords,
  emailSuppressionBulkInsertFromTable,
  globalSuppressionBulkInsertFromTable,
  getEmailSuppression,
  getGlobalSuppression,
  getEmailSuppressionSummary,
  getGlobalSuppressionSummary,
  emailSuppressionCodeCount,
  globalSuppressionCodeCount,
  getTargetData,
  targetdDataUploadMatch,
  getTDEmailDownloadCheck,
  getTargetDataHeader,
  getDrLookup,
  getDrLookupDetails,
  getDrLookupFileTypes,
  getDrLookupSpecialties,
  getDrLookupStates,
  getFileTypes,
  getUnassignedTAs,
  getOrders,
  getOmitCodeFileTypes,
  getOmitCodesStart,
  addOmitID,
  getDownloadList,
  importUpdateMatch,
  setCampaignTA,
  unassignCampaignTA,
  GetDomainStatsLastXDays,
  GetSendingDomainOverview,
  getSelectDeviceSize,
  insertDeviceSize,
  updateDeviceSize,
  deleteDeviceSize
}
