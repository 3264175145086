import client, { parseItem, handleError, formUploadConfig, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/contact'

const getContacts = async function (mailingItemContact, noLoad = false) {
  try {
    const response = await client.post(`${controllerPrefix}/mailingItem`, mailingItemContact, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTenantContacts = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/tenant/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getPersonalization = async function (targetId) {
  try {
    const response = await client.get(`${controllerPrefix}/personalization/${targetId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getPersonalizationsCoverage = async function (targetId) {
  try {
    const response = await client.get(`${controllerPrefix}/personalization/coverage/${targetId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createContact = async function (contact) {
  try {
    const response = await client.post(`${controllerPrefix}/createContact`, contact, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const removeContact = async function (mailingItemContact) {
  try {
    const response = await client.post(`${controllerPrefix}/removeContact`, mailingItemContact)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addContact = async function (mailingItemContact) {
  try {
    const response = await client.post(`${controllerPrefix}/addContact`, mailingItemContact)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteContacts = async function (contactIds) {
  try {
    const response = await client.post(`${controllerPrefix}/deleteContacts`, contactIds)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updatePersonalizationMeta = async function (personalizationMeta) {
  try {
    const response = await client.post(`${controllerPrefix}/updatePersonalizationMeta`, personalizationMeta, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateContactPersonalizations = async function (contact) {
  try {
    const response = await client.post(`${controllerPrefix}/updateContactPersonalizations`, contact)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadContacts = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/uploadContacts`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const contactService = {
  getContacts,
  getTenantContacts,
  getPersonalization,
  getPersonalizationsCoverage,
  createContact,
  removeContact,
  addContact,
  deleteContacts,
  updatePersonalizationMeta,
  updateContactPersonalizations,
  uploadContacts
}
