import { render, staticRenderFns } from "./ItemGroup.vue?vue&type=template&id=6da8545e&"
import script from "./ItemGroup.vue?vue&type=script&lang=js&"
export * from "./ItemGroup.vue?vue&type=script&lang=js&"
import style0 from "./ItemGroup.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VImg,VListGroup,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6da8545e')) {
      api.createRecord('6da8545e', component.options)
    } else {
      api.reload('6da8545e', component.options)
    }
    module.hot.accept("./ItemGroup.vue?vue&type=template&id=6da8545e&", function () {
      api.rerender('6da8545e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/ItemGroup.vue"
export default component.exports