
export class CreativeModel {
  Html: string;
  FileName: string;
  Path: string;
  ImagePath: string;
  Modified: Date;
  TenantCrmId: string;
  MailingId: string;
  SaveToContentLibrary: boolean

  constructor (Html?: string) {
    this.Html = Html || ''
  }
}
