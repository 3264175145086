import client, { parseItem } from '../axios-config'
import store from '@/store'

const controllerPrefix = '/layout'

const addNotification = async function (notif) {
  try {
    const response = await client.post(`${controllerPrefix}/addNotification`, notif)
    const idResp = parseItem(response, 200)
    return idResp
  } catch (error) {
    console.error(error)
    return error
  }
}

const clearNotification = async function (id) {
  try {
    const response = await client.delete(`${controllerPrefix}/clearNotification`, id)
    const data = response.data
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

const clearAllNotifications = async function () {
  try {
    const response = await client.delete(`${controllerPrefix}/clearAllNotifications`, store.getters['auth/getUserId']())
    const data = response.data
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export const layoutService = {
  addNotification,
  clearNotification,
  clearAllNotifications
}
