import client, { parseItem, handleError, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/spam/hints'

const getAllSpamHints = async function () {
  try {
    const response = await client.get(`${controllerPrefix}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createSpamHint = async function (params: {
  code: string;
  hint: string;
}) {
  try {
    const response = await client.post(`${controllerPrefix}`, params)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const editSpamHint = async function (params: {
  code: string;
  hint: string;
}) {
  try {
    const response = await client.put(`${controllerPrefix}`, params)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteSpamHint = async function (params: {
  code: string;
}) {
  try {
    const response = await client.delete(`${controllerPrefix}`, {
      data: {
        code: params.code
      }
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const spamHints = {
  getAllSpamHints,
  createSpamHint,
  editSpamHint,
  deleteSpamHint
}
