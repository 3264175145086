import client, { parseItem, handleError, formUploadConfig, noLoadingConfig } from '../axios-config'
import { CreativeModel } from '@/shared/models/content-library'

const controllerPrefix = '/azureblob'

const browse = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/browse`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const renameBlob = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/blob/rename`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteItems = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/delete`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadBlobs = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/blobs/upload`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadBlobsOnedrive = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/blobs/upload/onedrive`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadSplitHtml = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/split/upload`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadTd = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/uploadtd`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadtdmap = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/uploadtdmap`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const saveSplitHtml = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/split/save/html`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const downloadBlobs = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/blobs/download`, data, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadSplit = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/split/download/${splitId}`, { ...noLoadingConfig, responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const renameFolder = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/folder/rename`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createFolder = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/folder/create`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const folderExists = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/folder/exists`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const downloadFolder = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/folder/download`, data, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const getCreative = async function (data: CreativeModel) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/html`, { tenantCrmId: data.TenantCrmId, azHtmlLocation: data.Path })

    const model = new CreativeModel()
    model.ImagePath = response.data.imagePath
    model.Path = response.data.path
    model.Modified = response.data.modified
    model.FileName = response.data.fileName
    model.Html = response.data.html
    model.TenantCrmId = data.TenantCrmId
    model.MailingId = data.MailingId

    return model
  } catch (error) {
    return handleError(error)
  }
}

const getCLHtml = async function (data, noLoad = false) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/html`, { tenantCrmId: data.tenantCrmId, azHtmlLocation: data.path, isPreview: data.isPreview }, noLoad ? noLoadingConfig : {})
    return response.data.html
  } catch (error) {
    return handleError(error)
  }
}

const getSplitHtml = async function (splitId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/split/html/${splitId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setSplitLink = async function (splitId, link) {
  try {
    const response = await client.post(`${controllerPrefix}/split/setLink/${splitId}`, link)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSplitHtmlPreview = async function (splitId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/split/html/preview/${splitId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyCLHtmlToSplit = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/copyHtml/cl/stage`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyTemplatesHTMLToSplit = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/copyHtml/templates/stage`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyTemplatesHTMLToCL = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/copyHtml/templates/cl`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const saveCreative = async function (data: CreativeModel) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/save`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const newCreative = async function (data: CreativeModel) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/create`, data)
    const result = new CreativeModel()
    result.FileName = data.FileName
    result.Html = '<html><body></body></html>'
    result.Path = data.Path + data.FileName
    result.ImagePath = response.data.imagePath
    result.TenantCrmId = data.TenantCrmId

    return result
  } catch (error) {
    return handleError(error)
  }
}

const getImages = async function (tenantCrmId: string, root: string) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/images`, { tenantCrmId, root })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyImage = async function (tenantCrmId: string, source: string, destination: string) {
  try {
    const response = await client.post(`${controllerPrefix}/editor/copyImage`, { tenantCrmId, objectToCopy: source, destinationPath: destination })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getHelpArticles = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/kb/list`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getHelpArticle = async function (articleId: string) {
  try {
    const response = await client.post(`${controllerPrefix}/kb/article`, { azHtmlLocation: articleId })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReleaseNotes = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/kb/releaseNotes`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getEmailTemplates = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/templates/list`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportZip = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportReceived = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/received/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportOpens = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/opens/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportClicks = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/clicks/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportOptOuts = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/optOuts/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadComprehensiveReportEnhanced = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/comprehensive/enhanced/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadLeadScoresReport = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/download/leadScores/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadEmailAnalyticsReport = async function (analyticsResultRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/download/EmailAnalyticsReport`, analyticsResultRequest, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const downloadDirectMailAnalyticsReport = async function (directEmailAnalyticsResultRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/download/directMailAnalyticsReport`, directEmailAnalyticsResultRequest, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const azBlobService = {
  browse,
  renameBlob,
  deleteItems,
  uploadBlobs,
  uploadBlobsOnedrive,
  uploadTd,
  uploadtdmap,
  downloadBlobs,
  downloadSplit,
  renameFolder,
  createFolder,
  folderExists,
  downloadFolder,
  getCreative,
  getCLHtml,
  getSplitHtml,
  setSplitLink,
  getSplitHtmlPreview,
  copyCLHtmlToSplit,
  copyTemplatesHTMLToSplit,
  copyTemplatesHTMLToCL,
  saveCreative,
  newCreative,
  getImages,
  copyImage,
  uploadSplitHtml,
  saveSplitHtml,
  getHelpArticles,
  getHelpArticle,
  getReleaseNotes,
  getEmailTemplates,
  downloadComprehensiveReportZip,
  downloadComprehensiveReportReceived,
  downloadComprehensiveReportOpens,
  downloadComprehensiveReportClicks,
  downloadComprehensiveReportOptOuts,
  downloadComprehensiveReportEnhanced,
  downloadLeadScoresReport,
  downloadEmailAnalyticsReport,
  downloadDirectMailAnalyticsReport
}
