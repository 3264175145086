import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '@/plugins/vuetify'
import auth from './modules/auth'
import mailing from './modules/mailing'
import mailingDialog from './modules/mailing'
import appConfig from './modules/appConfig'
// import { mailing, mailingDialog } from '@/store/modules/mailing'
import moment from 'moment'
import creative from './modules/creative'
import MMSLight from '@/design/mms-light-theme'
import createPersistedState from 'vuex-persistedstate'
import { DefaultErrorMessage } from '@/shared/axios-config'

import { layoutService, userService, tenantService } from '../shared/services'

import {
  INITIALIZE_STORE,
  SET_DRAWER,
  SET_DISPLAY_DRAWER,
  SET_CHAT_DRAWER,
  SET_GLOBAL_BANNER,
  SET_GLOBAL_SNACKBAR,
  CLEAR_GLOBAL_SNACKBAR_ON_PAGE_CHANGE,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  OPEN_HTTP_REQ,
  CLOSE_HTTP_REQ,
  RESET_HTTP_REQ,
  SET_LOADING_MESSAGE,
  SET_USER_STATE,
  SET_IS_DARK_MODE,
  SET_VIEW_AS_CUSTOMER,
  SET_VIEW_AS_CUSTOMER_ACOUSTIC_LOGIN_ID,
  SET_FILTER_USER_NOWW_ID,
  SET_DASHBOARD_VIEW_MODE,
  SET_CALENDAR_FOCUS_DATE,
  SET_CALENDAR_VIEW_TYPE,
  SET_DAYS_FROM_NOW,
  SET_ORDER_TYPE_FILTER,
  SET_FILE_TYPE_FILTER,
  SET_TA_STATUS,
  SET_CAMPAIGN_STATUS,
  SET_REPORT_WIDGETS
} from './mutation-types'

Vue.use(Vuex)

function initialState () {
  return {
    loadingMessage: null,
    barColor: MMSLight.secondary,
    drawer: null,
    displayDrawer: true,
    chatDrawer: false,
    globalBanner: { show: false },
    globalSnackbar: { show: false, isPageChange: false },
    globalOpenHttpCount: 0,
    notifications: [],
    isDarkMode: false,
    viewAsCustomer: -1,
    viewAsCustomerNowwId: null,
    viewAsCustomerAcousticLoginId: null,
    viewAsCustomerIsContracted: false,
    dashboardViewMode: 'grid',
    calendarFocusDate: moment(),
    calendarViewType: 'month',
    daysFromNow: -31,
    filterUserNowwId: 0,
    orderTypeFilter: null,
    fileTypeFilter: 0,
    taStatus: null,
    campaignStatus: null,
    reportWidgets: []
  }
}

const actions = {
  resetAll ({ commit }) {
    commit('mailing/reset')
    commit('mailingDialog/reset')
    commit('auth/reset')
    commit('creative/reset')
    commit('reset')
  },
  async getUserState ({ commit, dispatch }, { userId, token }) {
    const resp = await userService.getUserState(userId, token)
    if (resp.errorResponse) return resp
    // if (resp.viewAsCustomer) {
    //   const resp2 = await tenantService.getTenantByCrmId(resp.viewAsCustomer)
    //   if (resp2.errorResponse) return resp2
    //   resp.viewAsCustomerNowwId = resp2.nowwId
    // }
    commit(SET_USER_STATE, resp)
    return resp
  },
  async setIsDarkMode ({ commit }, isDarkMode) {
    commit(SET_IS_DARK_MODE, isDarkMode)
    const resp = await userService.updateDarkMode({ userId: this.getters['auth/getUserId'](), isDarkMode })
    return resp
  },
  async setViewAsCustomer ({ commit }, { crmId, nowwId, isContracted }) {
    commit(SET_VIEW_AS_CUSTOMER, { crmId, nowwId, isContracted })
    if (this.getters['filterUserNowwId']() !== 0 && this.getters['filterUserNowwId']() !== this.getters['auth/getUserNowwId']()) {
      commit(SET_FILTER_USER_NOWW_ID, 0)
    }
    let reqCustomer = this.getters['isViewAllCustomers']()
      ? { userId: this.getters['auth/getUserId']() }
      : { userId: this.getters['auth/getUserId'](), viewAsCustomer: crmId }
    const resp = userService.updateViewAsCustomer(reqCustomer)
    if (!this.getters['isViewAllCustomers']()) {
      const resp2 = await tenantService.createTenantFromCrm({ crmId, nowwId })
      commit(SET_VIEW_AS_CUSTOMER_ACOUSTIC_LOGIN_ID, resp2)
    }
    return resp
  },
  async setFilterUserNowwId ({ commit }, filterUserNowwId) {
    commit(SET_FILTER_USER_NOWW_ID, filterUserNowwId)
    const resp = await userService.updateFilterUserNowwId({ userId: this.getters['auth/getUserId'](), filterUserNowwId })
    return resp
  },
  async setDashboardViewMode ({ commit }, dashboardViewMode) {
    commit(SET_DASHBOARD_VIEW_MODE, dashboardViewMode)
    const resp = await userService.updateDashboardViewMode({ userId: this.getters['auth/getUserId'](), dashboardViewMode })
    return resp
  },
  async setCalendarFocusDate ({ commit }, calendarFocusDate) {
    commit(SET_CALENDAR_FOCUS_DATE, calendarFocusDate)
    const resp = await userService.updateCalendarFocusDate({ userId: this.getters['auth/getUserId'](), calendarFocusDate })
    return resp
  },
  async setCalendarViewType ({ commit }, calendarViewType) {
    commit(SET_CALENDAR_VIEW_TYPE, calendarViewType)
    const resp = await userService.updateCalendarViewType({ userId: this.getters['auth/getUserId'](), calendarViewType })
    return resp
  },
  async setDaysFromNow ({ commit }, daysFromNow) {
    commit(SET_DAYS_FROM_NOW, daysFromNow)
    const resp = await userService.updateDaysFromNow({ userId: this.getters['auth/getUserId'](), daysFromNow })
    return resp
  },
  async setOrderTypeFilter ({ commit }, orderTypeFilter) {
    commit(SET_ORDER_TYPE_FILTER, orderTypeFilter)
    const resp = await userService.updateOrderTypeFilter({ userId: this.getters['auth/getUserId'](), orderTypeFilter })
    return resp
  },
  async setFileTypeFilter ({ commit }, fileTypeFilter) {
    commit(SET_FILE_TYPE_FILTER, fileTypeFilter)
    const resp = await userService.updateFileTypeFilter({ userId: this.getters['auth/getUserId'](), fileTypeFilter })
    return resp
  },
  async setTAStatus ({ commit }, taStatus) {
    commit(SET_TA_STATUS, taStatus)
    const resp = await userService.updateTAStatus({ userId: this.getters['auth/getUserId'](), taStatus })
    return resp
  },
  async setCampaignStatus ({ commit }, campaignStatus) {
    commit(SET_CAMPAIGN_STATUS, campaignStatus)
    const resp = await userService.updateCampaignStatus({ userId: this.getters['auth/getUserId'](), campaignStatus })
    return resp
  },
  async setReportWidgets ({ commit }, reportWidgets) {
    commit(SET_REPORT_WIDGETS, reportWidgets)
    const resp = await userService.updateReportWidgets({ userId: this.getters['auth/getUserId'](), reportDashboardWidgets: reportWidgets })
    return resp
  },
  async addNotification ({ commit }, notification) {
    const addedNotif = await layoutService.addNotification(notification)
    commit(ADD_NOTIFICATION, addedNotif)
    return addedNotif
  },
  async clearNotification ({ commit }, id) {
    const notifId = await layoutService.clearNotification(id)
    commit(CLEAR_NOTIFICATION, notifId)
    return notifId
  },
  async clearAllNotifications ({ commit }) {
    const notifResp = await layoutService.clearAllNotifications()
    commit(CLEAR_ALL_NOTIFICATIONS, notifResp)
    return notifResp
  }
}

const mutations = {
  reset (state) { // https://github.com/vuejs/vuex/issues/1118
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [INITIALIZE_STORE] (state) {
    // Check if the ID exists
    if (localStorage.getItem('store')) {
      // Replace the state object with the stored item
      this.replaceState(
        Object.assign(state, JSON.parse(localStorage.getItem('store')))
      )
    }
  },
  [SET_DRAWER] (state, payload) {
    state.drawer = payload
  },
  [SET_DISPLAY_DRAWER] (state, payload) {
    state.displayDrawer = payload
  },
  [SET_CHAT_DRAWER] (state, payload) {
    state.chatDrawer = payload
  },
  [SET_GLOBAL_BANNER] (state, payload) {
    if (payload) {
      state.globalBanner = payload
      if (!payload.show) {
        state.globalBanner.show = true
      }
    } else {
      state.globalBanner = initialState().globalBanner
    }
  },
  [SET_GLOBAL_SNACKBAR] (state, payload) {
    if (payload) {
      if (typeof payload.message !== 'string') {
        payload.message = DefaultErrorMessage
      }
      state.globalSnackbar = payload
      if (!payload.show) {
        state.globalSnackbar.show = true
      }
      if (!payload.isPageChange) {
        state.globalSnackbar.isPageChange = false
      }
    } else {
      state.globalSnackbar = initialState().globalSnackbar
    }
  },
  [CLEAR_GLOBAL_SNACKBAR_ON_PAGE_CHANGE] (state, payload) {
    if (state.globalSnackbar && state.globalSnackbar.isPageChange) {
      state.globalSnackbar.isPageChange = false
    } else {
      state.globalSnackbar = initialState().globalSnackbar
    }
  },
  [ADD_NOTIFICATION] (state, notification) {
    state.notifications.unshift(notification)
  },
  [CLEAR_NOTIFICATION] (state, id) {
    state.notifications = [...state.notifications.filter(p => p.id !== id)]
  },
  [CLEAR_ALL_NOTIFICATIONS] (state, payload) {
    state.notifications = []
  },
  [OPEN_HTTP_REQ] (state, payload) {
    state.globalOpenHttpCount++
  },
  [CLOSE_HTTP_REQ] (state, payload) {
    if (state.globalOpenHttpCount > 0) state.globalOpenHttpCount--
    else if (process.env.VUE_APP_IS_DEBUG === 'true') console.warn('Closing too many http requests?')
    if (state.globalOpenHttpCount === 0) state.loadingMessage = null
  },
  [RESET_HTTP_REQ] (state, payload) {
    state.globalOpenHttpCount = 0
  },
  [SET_LOADING_MESSAGE] (state, payload) {
    state.loadingMessage = payload
  },
  [SET_USER_STATE] (state, payload) {
    state.isDarkMode = payload.isDarkMode
    Vuetify.framework.theme.dark = payload.isDarkMode
    state.viewAsCustomer = payload.viewAsCustomer || -1
    state.viewAsCustomerNowwId = payload.viewAsCustomerNowwId || null
    state.viewAsCustomerAcousticLoginId = payload.viewAsCustomerAcousticLoginId
    state.viewAsCustomerIsContracted = payload.viewAsCustomerIsContracted
    state.dashboardViewMode = payload.dashboardViewMode
    state.calendarFocusDate = payload.calendarFocusDate
    state.calendarViewMode = payload.calendarViewMode
    state.filterUserNowwId = payload.filterUserNowwId
    state.daysFromNow = payload.daysFromNow
    state.orderTypeFilter = payload.orderTypeFilter
    state.fileTypeFilter = payload.fileTypeFilter
    state.taStatus = payload.taStatus
    state.campaignStatus = payload.campaignStatus
    state.reportWidgets = payload.reportDashboardWidgets
  },
  [SET_IS_DARK_MODE] (state, payload) {
    state.isDarkMode = payload
    Vuetify.framework.theme.dark = payload
  },
  [SET_VIEW_AS_CUSTOMER] (state, payload) {
    state.viewAsCustomer = payload.crmId || -1
    state.viewAsCustomerNowwId = payload.nowwId || null
    state.viewAsCustomerIsContracted = payload.isContracted || false
  },
  [SET_VIEW_AS_CUSTOMER_ACOUSTIC_LOGIN_ID] (state, payload) {
    state.viewAsCustomerAcousticLoginId = payload
  },
  [SET_DASHBOARD_VIEW_MODE] (state, payload) {
    state.dashboardViewMode = payload
  },
  [SET_CALENDAR_FOCUS_DATE] (state, payload) {
    state.calendarFocusDate = payload
  },
  [SET_CALENDAR_VIEW_TYPE] (state, payload) {
    state.calendarViewType = payload
  },
  [SET_FILTER_USER_NOWW_ID] (state, payload) {
    state.filterUserNowwId = payload
  },
  [SET_DAYS_FROM_NOW] (state, payload) {
    state.daysFromNow = payload
  },
  [SET_ORDER_TYPE_FILTER] (state, payload) {
    state.orderTypeFilter = payload
  },
  [SET_FILE_TYPE_FILTER] (state, payload) {
    state.fileTypeFilter = payload
  },
  [SET_TA_STATUS] (state, payload) {
    state.taStatus = payload
  },
  [SET_CAMPAIGN_STATUS] (state, payload) {
    state.campaignStatus = payload
  },
  [SET_REPORT_WIDGETS] (state, payload) {
    state.reportWidgets = payload
  }
}

const getters = {
  isLoading: state => () => state.globalOpenHttpCount !== 0,
  isLoadingOverlay: (state, getters) => () => getters.isLoading() && state.loadingMessage,
  loadingMessage: state => () => state.loadingMessage,
  globalSnackbar: state => () => state.globalSnackbar,
  isDarkMode: state => () => state.isDarkMode,
  viewAsCustomer: state => () => state.viewAsCustomer,
  viewAsCustomerNowwId: state => () => state.viewAsCustomerNowwId,
  viewAsCustomerAcousticLoginId: state => () => state.viewAsCustomerAcousticLoginId,
  viewAsCustomerIsContracted: state => () => state.viewAsCustomerIsContracted,
  isViewAllCustomers: state => () => state.viewAsCustomer === 'All Customers' || state.viewAsCustomer === -1,
  isViewAllCustomersVal: state => (val) => val === 'All Customers' || val === -1,
  simulatedCustomerCrmId: (state, getters) => () => getters.isViewAllCustomers() ? getters['auth/getTenantCrmId']() : getters.viewAsCustomer(),
  simulatedCustomerNowwId: (state, getters) => () => getters.isViewAllCustomers() ? getters['auth/getTenantNowwId']() : getters.viewAsCustomerNowwId(),
  simulatedCustomerIsContracted: (state, getters) => () => getters.isViewAllCustomers() ? true : getters.viewAsCustomerIsContracted(),
  isViewAsMMS: (state, getters) => () => getters['auth/isMMSRole']() && (getters.viewAsCustomer() === getters['auth/getTenantCrmId']()),
  isSimulatedMMS: (state, getters) => () => getters['auth/isMMSRole']() && (getters.simulatedCustomerCrmId() === getters['auth/getTenantCrmId']()),
  isSimulatedAsCustomer: (state, getters) => () => getters['auth/isMMSRole']() && (getters.simulatedCustomerCrmId() !== getters['auth/getTenantCrmId']()),
  filterUserNowwId: state => () => state.filterUserNowwId,
  daysFromNow: state => () => state.daysFromNow,
  dashboardViewMode: state => () => state.dashboardViewMode,
  calendarFocusDate: state => () => state.calendarFocusDate,
  calendarViewType: state => () => state.calendarViewType,
  orderTypeFilter: state => () => state.orderTypeFilter,
  fileTypeFilter: state => () => state.fileTypeFilter,
  taStatus: state => () => state.taStatus,
  campaignStatus: state => () => state.campaignStatus,
  displayDrawer: state => () => state.displayDrawer,
  chatDrawer: state => () => state.chatDrawer,
  reportWidgets: state => () => state.reportWidgets
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    appConfig,
    auth,
    mailing,
    mailingDialog,
    creative
  },
  plugins: [createPersistedState()],
  state: initialState(),
  mutations,
  actions,
  getters
})
