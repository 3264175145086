import { nowwService } from '@/shared/services'

function initialState () {
  return {
    deviceSizes: []
  }
}

const actions = {
  async fetchDevicePreviewDeviceSizes({ commit, dispatch, getters }) {
    const resp = await nowwService.getSelectDeviceSize()
    if (resp.errorResponse) {
      return resp
    }
    commit('setDevicePreviewDeviceSizes', resp)
  }
}

const mutations = {

  setDevicePreviewDeviceSizes (state, data) {
    const formattedRes = data
        .filter(({ omitFromDropDown }) => !omitFromDropDown)
        .map((device) => {
          return {
            value: device.id,
            text: device.device,
            width: device.sizeX,
            height: device.sizeY
          }
        })
    state.deviceSizes = formattedRes
  },
}

const getters = {
  getDevicePreviewSizes: (state, getters) => () => state.deviceSizes
}

export default {
  namespaced: true,
  actions,
  mutations,
  state: initialState(),
  getters
}
