export const INITIALIZE_STORE = 'initializeStore'
export const SET_DRAWER = 'setDrawer'

export const SET_DISPLAY_DRAWER = 'setDisplayDrawer'
export const SET_CHAT_DRAWER = 'setChatDrawer'
export const SET_GLOBAL_BANNER = 'setGlobalBanner'
export const SET_GLOBAL_SNACKBAR = 'setGlobalSnackbar'
export const CLEAR_GLOBAL_SNACKBAR_ON_PAGE_CHANGE = 'clearGLobalSnackbarOnPageChange'
export const ADD_NOTIFICATION = 'addNotification'
export const CLEAR_NOTIFICATION = 'clearNotification'
export const CLEAR_ALL_NOTIFICATIONS = 'clearAllNotifications'
export const OPEN_HTTP_REQ = 'openHttpReq'
export const CLOSE_HTTP_REQ = 'closeHttpReq'
export const RESET_HTTP_REQ = 'resetHttpReq'
export const SET_LOADING_MESSAGE = 'setLoadingMessage'
export const SET_USER_STATE = 'setUserState'
export const SET_IS_DARK_MODE = 'setIsDarkMode'
export const SET_VIEW_AS_CUSTOMER = 'setViewAsCustomer'
export const SET_VIEW_AS_CUSTOMER_ACOUSTIC_LOGIN_ID = 'setViewAsCustomerAcousticLoginId'
export const SET_FILTER_USER_NOWW_ID = 'setFilterUserNowwId'
export const SET_DASHBOARD_VIEW_MODE = 'setDashboardViewMode'
export const SET_CALENDAR_FOCUS_DATE = 'setCalendarFocusDate'
export const SET_CALENDAR_VIEW_TYPE = 'setCalendarViewType'
export const SET_DAYS_FROM_NOW = 'setDaysFromNow'
export const SET_ORDER_TYPE_FILTER = 'setOrderTypeFilter'
export const SET_FILE_TYPE_FILTER = 'setFileTypeFilter'
export const SET_TA_STATUS = 'setTAStatus'
export const SET_CAMPAIGN_STATUS = 'setCampaignStatus'
export const SET_REPORT_WIDGETS = 'setReportWidgets'

// TODO: refactor to captial snake case, action-types, and models from https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
// import {  } from '@/store/mutation-types'

export default {
  INITIALIZE_STORE,
  SET_DRAWER,
  SET_DISPLAY_DRAWER,
  SET_CHAT_DRAWER,
  SET_GLOBAL_BANNER,
  SET_GLOBAL_SNACKBAR,
  CLEAR_GLOBAL_SNACKBAR_ON_PAGE_CHANGE,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  OPEN_HTTP_REQ,
  CLOSE_HTTP_REQ,
  RESET_HTTP_REQ,
  SET_LOADING_MESSAGE,
  SET_USER_STATE,
  SET_IS_DARK_MODE,
  SET_VIEW_AS_CUSTOMER,
  SET_VIEW_AS_CUSTOMER_ACOUSTIC_LOGIN_ID,
  SET_DASHBOARD_VIEW_MODE,
  SET_CALENDAR_FOCUS_DATE,
  SET_CALENDAR_VIEW_TYPE,
  SET_FILTER_USER_NOWW_ID,
  SET_DAYS_FROM_NOW,
  SET_ORDER_TYPE_FILTER,
  SET_FILE_TYPE_FILTER,
  SET_TA_STATUS,
  SET_CAMPAIGN_STATUS,
  SET_REPORT_WIDGETS
}
