import client, { parseItem, handleError } from '../axios-config'

const controllerPrefix = '/mailingAddress'

const getBatchedMailingAddresses = async function ({
  firstName,
  lastName,
  state,
  zipCode,
  fileType
}: {
 firstName: string,
 lastName: string,
 state: string,
 zipCode: string,
 fileType: string
}) {
  try {
    const response = await client.get(`${controllerPrefix}/batchedMailingAddresses`, {
      params: {
        fname: firstName,
        lname: lastName,
        state,
        zip: zipCode,
        filetype: fileType
      }
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getMailingAddressesBatchedChanges = async function (fileType: string) {
  try {
    const response = await client.get(`${controllerPrefix}/mailingAddressesBatchedChanges`, {
      params: {
        fileType
      }
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export enum GetMailingAddressByIdIdType {
  Default,
  SubFile,
  MmsId,
  Npi,
}

const getMailingAddressById = async function (id: string, idType: GetMailingAddressByIdIdType) {
  try {
    const response = await client.get(`${controllerPrefix}/mailingAddress`, {
      params: {
        id,
        idType
      }
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getMailingAddrUnexportedBatches = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/mailingAddrUnexportedBatches`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getStates = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/states`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const postBatchedChanges = async function ({
  firstName,
  lastName,
  adr1,
  adr2,
  city,
  state,
  zip,
  fileType,
  fileId
}: {
  firstName: string,
  lastName: string,
  adr1: string,
  adr2: string,
  city: string,
  state: string,
  zip: string,
  fileType: string,
  fileId: string
}) {
  try {
    const response = await client.post(`${controllerPrefix}/mailingAddressBatchedChanges`, {
      Fname: firstName,
      Lname: lastName,
      adr1,
      adr2,
      city,
      state,
      zip,
      fileType,
      fileId
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const mailingAddressService = {
  getBatchedMailingAddresses,
  getMailingAddressesBatchedChanges,
  getMailingAddressById,
  getMailingAddrUnexportedBatches,
  getStates,
  postBatchedChanges
}
