const HPEBS = 'HPEBS'
// TODO: get jsdoc comments to work with these
/**
 * @description Healthcare Provider Email Broadcast Services - Email Broadcast Services
 */
export const HPEBS_EBS = { key: HPEBS, value: 'EBS' }

const HPML = 'HPML'
export const HPML_DL = { key: HPML, value: 'DL' }

const ITA = 'ITA'
export const ITA_DSC = { key: ITA, value: 'DSC' }
export const ITA_ES = { key: ITA, value: 'ES' }
export const ITA_GES = { key: ITA, value: 'GES' }
export const ITA_TFM = { key: ITA, value: 'TFM' }
export const ITA_PL = { key: ITA, value: 'PL' }
export const ITA_PGO = { key: ITA, value: 'PGO' }
export const ITA_PLS = { key: ITA, value: 'PLS' }
export const ITA_EB = { key: ITA, value: 'EB' }
// export const ITA_CLR = { key: ITA, value: 'CLR' }

const BS = 'BS'
export const BS_AB = { key: BS, value: 'AB' }
export const BS_RP = { key: BS, value: 'RP' }
export const BS_CE = { key: BS, value: 'CE' }
export const BS_SSP = { key: BS, value: 'SSP' }
export const BS_TB = { key: BS, value: 'TB' }
export const BS_SPB = { key: BS, value: 'SPB' }
export const BS_SC = { key: BS, value: 'SC' }
export const BS_OOS = { key: BS, value: 'OOS' }
export const BS_ODTS = { key: BS, value: 'ODTS' }
export const BS_MENR = { key: BS, value: 'MENR' }
export const BS_TA = { key: BS, value: 'TA' }

const R = 'R'
export const R_SER = { key: R, value: 'SER' }
export const R_LSP = { key: R, value: 'LSP' }
export const R_EER = { key: R, value: 'EER' }
export const R_BIR = { key: R, value: 'BIR' }
export const R_BIH = { key: R, value: 'BIH' }
export const R_KPIW = { key: R, value: 'KPIW' }
export const R_LD = { key: R, value: 'LD' }
export const R_AD = { key: R, value: 'AD' }
export const R_DMAR = { key: R, value: 'DMAR' }
export const R_EAR = { key: R, value: 'EAR' }
export const R_SLSR = { key: R, value: 'SLSR' }
export const R_SLOTR = { key: R, value: 'SLOTR' }
export const R_SPER = { key: R, value: 'SPER' }
export const R_TBPR = { key: R, value: 'TBPR' }
export const R_BDR = { key: R, value: 'BDR' }
export const R_ECR = { key: R, value: 'ECR' }

const AD = 'AD'
export const AD_BH = { key: AD, value: 'BH' }
export const AD_UM = { key: AD, value: 'UM' }
export const AD_UV = { key: AD, value: 'UV' }
export const AD_AS = { key: AD, value: 'AS' }

const CL = 'CL'
export const CL_CLM = { key: CL, value: 'CLM' }
export const CL_DF = { key: CL, value: 'DF' }

const MMS = 'MMS'
export const MMS_TM = { key: MMS, value: 'TM' }
export const MMS_TEBT = { key: MMS, value: 'TEBT' }
export const MMS_TC = { key: MMS, value: 'TC' }
export const MMS_TRA = { key: MMS, value: 'TRA' }
export const MMS_ER = { key: MMS, value: 'ER' }

export default {
  HPEBS_EBS,
  HPML_DL,
  ITA_DSC,
  ITA_ES,
  ITA_GES,
  ITA_TFM,
  ITA_PL,
  ITA_PGO,
  ITA_PLS,
  ITA_EB,
  // ITA_CLR,
  BS_AB,
  BS_RP,
  BS_CE,
  BS_SSP,
  BS_TB,
  BS_SPB,
  BS_SC,
  BS_OOS,
  BS_ODTS,
  BS_MENR,
  BS_TA,
  R_SER,
  R_LSP,
  R_EER,
  R_BIR,
  R_BIH,
  R_KPIW,
  R_LD,
  R_AD,
  R_DMAR,
  R_EAR,
  R_SLSR,
  R_SLOTR,
  R_SPER,
  R_TBPR,
  R_BDR,
  R_ECR,
  AD_BH,
  AD_UM,
  AD_UV,
  AD_AS,
  CL_CLM,
  CL_DF,
  MMS_TM,
  MMS_TEBT,
  MMS_TC,
  MMS_TRA,
  MMS_ER
}
