export class TableCSVExporter {
  constructor (tables, headerTables = new Array(0)) {
    this.tables = tables
    this.headerTables = headerTables
    this.rows = new Array(tables.length)
    var _this = this
    tables.forEach((x, index) => {
      _this.rows[index] = Array.from(x.querySelectorAll('tr'))
    })
    this.headerRows = new Array(headerTables.length)
    headerTables.forEach((x, index) => {
      _this.headerRows[index] = Array.from(x.querySelectorAll('tr'))
    })
  }

  convertToCSV () {
    const lines = []
    const numCols = this.findLongestRowLength()

    for (const headerRow of this.headerRows) {
      for (const row of headerRow) {
        let line = ''
        let numColsCopy = numCols
        for (let i = 0; i < numColsCopy; i++) {
          if (row.children[i] !== undefined) {
            let colspan = row.children[i].getAttribute('colspan')
            if (colspan !== null) {
              for (let cs = 1; cs < colspan; cs++) {
                line += TableCSVExporter.parseCell(row.children[i]) + ','
                numColsCopy--
              }
            }
            line += TableCSVExporter.parseCell(row.children[i])
          }

          line += (i !== (numColsCopy - 1)) ? ',' : ''
        }

        lines.push(line)
      }
    }

    if (this.headerTables.length) {
      lines.push(','.repeat(numCols - 1))
    }

    for (const rows of this.rows) {
      for (const row of rows) {
        let line = ''
        let numColsCopy = numCols
        for (let i = 0; i < numColsCopy; i++) {
          if (row.children[i] !== undefined) {
            let colspan = row.children[i].getAttribute('colspan')
            if (colspan !== null) {
              for (let cs = 1; cs < colspan; cs++) {
                line += TableCSVExporter.parseCell(row.children[i]) + ','
                numColsCopy--
              }
            }
            line += TableCSVExporter.parseCell(row.children[i])
          }

          line += (i !== (numColsCopy - 1)) ? ',' : ''
        }

        lines.push(line)
      }

      lines.push(','.repeat(numCols - 1))
    }
    return lines.join('\n') + '\n'
  }

  findLongestRowLength () {
    let array = []
    this.rows.forEach((tableRow, index) => {
      array.push(tableRow.reduce((l, row) => row.childElementCount > l ? row.childElementCount : l, 0))
    })
    this.headerRows.forEach((tableRow, index) => {
      array.push(tableRow.reduce((l, row) => row.childElementCount > l ? row.childElementCount : l, 0))
    })
    return Math.max(...array)
  }

  static parseCell (tableCell) {
    let parsedValue = tableCell.textContent.replace(/^\s+|\s+$/g, '')

    // Replace all double quotes with two double quotes
    parsedValue = parsedValue.replace(/"/g, `""`)

    // If value contains comma, remove it
    parsedValue = parsedValue.replace(/,/g, '')

    // If value contains new-line or double-quote, enclose in double quotes
    parsedValue = /["\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue

    return parsedValue
  }
}
