const imageExtensionsList = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif'
]

const htmlExtensionsList = [
  '.html',
  '.htm',
  '.zip'
]

const testContactExtensionsList = [
  '.csv',
  '.txt'
]

const textExtensionsList = [
  '.txt'
]

function getExtensionsString (list) {
  if (list === undefined) return ''
  return list.join(', ')
}

function getExtension (fileName) {
  return fileName.substr(fileName.lastIndexOf('.')) // includes "."
}

export const fileExtensions = {
  imageExtensionsList,
  htmlExtensionsList,
  testContactExtensionsList,
  textExtensionsList,
  getExtensionsString,
  getExtension
}
