import { authService, tenantService, userService } from '@/shared/services'
import mutationTypes from '@/store/mutation-types'
import store from '@/store'
import Vue from 'vue'
import { stat } from 'fs'

const COOKIE_NOWW_SESSION = 'X_mms_nlnoww_ss'
const COOKIE_TOKEN_KEY = 'X_mms_ins_t'
const COOKIE_REFRESH_TOKEN_KEY = 'X_mms_ins_rt'
const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN_NAME
const COOKIE_SECURE = (process.env.VUE_APP_COOKIE_SECURE === 'true')
const COOKIE_SAME_SITE = process.env.VUE_APP_COOKIE_SAME_SITE

const LOG_OUT = 'logOut'
const REFRESH_TOKENS = 'refreshTokens'
const SET_USER = 'setUser'
const CLEAR_COOKIES = 'clearCookies'
const SET_PASSWORD_EXPIRATION = 'setPasswordExpiration'
const SET_TERMS_ACCEPTED = 'setTermsAccepted'
const SET_LATEST_RELEASE_SEEN = 'setLatestReleaseSeen'

function initialState () {
  return {
    email: null,
    firstName: null,
    lastName: null,
    userId: null,
    userNowwId: null,
    crmUserId: null,
    crmContactId: null,
    tenantId: null,
    tenantNowwId: null,
    tenantCrmId: null,
    claims: [],
    passwordExpiresInDays: null,
    areTermsAcceptedLatest: null,
    areReleaseNotesSeenLatest: null,
    jwtToken: null
  }
}

const actions = {
  async logOut ({ commit, dispatch }) {
    await store.dispatch('resetAll')
  },
  async getUser ({ commit, dispatch }, { token, refreshToken, userId }) {
    const data = await tenantService.getUser(userId, token)
    if (data.errorResponse) return data
    commit(REFRESH_TOKENS, { token, refreshToken })
    commit(SET_USER, data)
    await store.dispatch('getUserState', { userId: data.userId, token })
    return data
  },
  async logIn ({ commit, dispatch }, { email, password }) {
    store.commit(mutationTypes.RESET_HTTP_REQ)
    store.commit(mutationTypes.SET_LOADING_MESSAGE, 'Logging you in')
    commit(CLEAR_COOKIES)
    const data = await authService.createToken({ email, password, clientId: process.env.VUE_APP_INSCRIPTIO_APP_CLIENT_ID })
    if (data.errorResponse) return data

    store.commit(mutationTypes.SET_LOADING_MESSAGE, 'Retrieving your profile')
    const user = await dispatch('getUser', data)
    if (user.errorResponse) return user
    // TODO: get notifications for user?

    store.commit(mutationTypes.SET_GLOBAL_SNACKBAR, {
      message: `Welcome ${user.firstName} ${user.lastName}!`,
      color: 'success',
      isPageChange: true
    })

    return user
  },
  async refreshTokens ({ commit, dispatch, getters }) {
    const data = await authService.refreshTokens({ token: getters.getToken(), refreshToken: Vue.$cookies.get(COOKIE_REFRESH_TOKEN_KEY), clientId: process.env.VUE_APP_INSCRIPTIO_APP_CLIENT_ID })
    if (data.errorResponse) throw data

    commit(REFRESH_TOKENS, data)
    const user = await dispatch('getUser', data)
    if (user.errorResponse) return user

    return user
  },
  async forgotPassword ({ commit, dispatch }, email) {
    store.commit(mutationTypes.SET_LOADING_MESSAGE, 'random')
    const data = await authService.forgotPassword(email)
    if (data.errorResponse) return data

    return data
  },
  async passwordReset ({ commit, dispatch }, passwordReset) {
    store.commit(mutationTypes.SET_LOADING_MESSAGE, 'Resetting password')
    const data = await authService.passwordReset(passwordReset)
    if (data.errorResponse) return data

    store.commit(mutationTypes.SET_GLOBAL_SNACKBAR, {
      message: `Password has been reset!`,
      color: 'success'
    })

    return data
  },
  async passwordChange ({ commit, dispatch }, passwordChange) {
    // store.commit(mutationTypes.SET_LOADING_MESSAGE, 'Changing password')
    const data = await tenantService.passwordChange(passwordChange)
    if (data.errorResponse) return data

    commit(SET_PASSWORD_EXPIRATION, data)
    store.commit(mutationTypes.SET_GLOBAL_SNACKBAR, {
      message: `Password has been changed!`,
      color: 'success'
    })

    return data
  },
  async acceptTerms ({ commit, dispatch }, model) {
    const data = await userService.acceptTerms(model)
    if (data.errorResponse) return data

    commit(SET_TERMS_ACCEPTED, true)
    // store.commit(mutationTypes.SET_GLOBAL_SNACKBAR, {
    //   message: `Password has been changed!`,
    //   color: 'success'
    // })

    return data
  },
  async seeReleaseNotes ({ commit, dispatch }, model) {
    const data = await userService.seeLatestReleaseNotes(model)
    if (data.errorResponse) return data

    commit(SET_LATEST_RELEASE_SEEN, true)

    return data
  }
}

const mutations = {
  reset (state) { // https://github.com/vuejs/vuex/issues/1118
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    Vue.$cookies.remove(COOKIE_TOKEN_KEY, '/', COOKIE_DOMAIN)
    Vue.$cookies.remove(COOKIE_REFRESH_TOKEN_KEY, '/', COOKIE_DOMAIN)
    if (Vue.$cookies.isKey(COOKIE_NOWW_SESSION)) Vue.$cookies.remove(COOKIE_NOWW_SESSION, '/', COOKIE_DOMAIN)
  },
  [LOG_OUT] (state, data) {
    Vue.$cookies.remove(COOKIE_TOKEN_KEY, '/', COOKIE_DOMAIN)
    Vue.$cookies.remove(COOKIE_REFRESH_TOKEN_KEY, '/', COOKIE_DOMAIN)
    state.jwtToken = null
    if (Vue.$cookies.isKey(COOKIE_NOWW_SESSION)) Vue.$cookies.remove(COOKIE_NOWW_SESSION, '/', COOKIE_DOMAIN)
    // state.email = null;
    state.userId = null
    state.userNowwId = null
    state.crmUserId = null
    state.crmContactId = null
    state.firstName = null
    state.lastName = null
    state.tenantId = null
    state.tenantNowwId = null
    state.tenantCrmId = null
    state.claims = []
    state.passwordExpiresInDays = null
    state.areTermsAcceptedLatest = null
    state.areReleaseNotesSeenLatest = null
    store.commit(mutationTypes.SET_USER_STATE, {
      isDarkMode: false,
      viewAsCustomer: null,
      filterUserNowwId: 0,
      daysFromNow: -31,
      fileTypeFilter: 0,
      orderTypeFilter: 0
    })
  },
  [SET_USER] (state, data) {
    state.email = data.email
    state.userId = data.userId
    state.userNowwId = data.userNowwId
    state.crmUserId = data.crmUserId
    state.crmContactId = data.crmContactId
    state.firstName = data.firstName
    state.lastName = data.lastName
    state.tenantId = data.tenantId
    state.tenantNowwId = data.tenantNowwId
    state.tenantCrmId = data.tenantCrmId
    state.claims = data.claims || []
    state.passwordExpiresInDays = data.passwordExpiresInDays
    state.areTermsAcceptedLatest = data.areTermsAcceptedLatest
    state.areReleaseNotesSeenLatest = data.areReleaseNotesSeenLatest
  },
  [REFRESH_TOKENS] (state, data) {
    // console.log(`mut isKey token?: ${Vue.$cookies.isKey(COOKIE_TOKEN_KEY)}`)
    // console.log(`mut isKey refresh?: ${Vue.$cookies.isKey(COOKIE_REFRESH_TOKEN_KEY)}`)
    Vue.$cookies.remove(COOKIE_TOKEN_KEY, '/', COOKIE_DOMAIN)
    Vue.$cookies.remove(COOKIE_REFRESH_TOKEN_KEY, '/', COOKIE_DOMAIN)
    // console.log(`mut token: ${data.token}`)
    // console.log(`mut refresh: ${data.refreshToken}`)
    // console.log('1-Domain:' + COOKIE_DOMAIN + ' Secure:' + COOKIE_SECURE + ' SameSite:' + COOKIE_SAME_SITE + ' TokenKey:' + COOKIE_TOKEN_KEY + ' TokenRefreshKey:' + COOKIE_REFRESH_TOKEN_KEY)
    Vue.$cookies.set(COOKIE_TOKEN_KEY, data.token, '20min', '/', COOKIE_DOMAIN, COOKIE_SECURE, COOKIE_SAME_SITE)
    state.jwtToken = data.token
    Vue.$cookies.set(COOKIE_REFRESH_TOKEN_KEY, data.refreshToken, '2h', '/', COOKIE_DOMAIN, COOKIE_SECURE, COOKIE_SAME_SITE)
    // https://www.npmjs.com/package/vue-cookies#set-expire-times--input-string-type
  },
  [CLEAR_COOKIES] (state, data) {
    // if (process.env.VUE_APP_IS_DEBUG === 'true') console.log(`Removing cookies: ${Vue.$cookies.keys().join(', ')}`)
    // Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
    Vue.$cookies.remove(COOKIE_TOKEN_KEY, '/', COOKIE_DOMAIN)
    Vue.$cookies.remove(COOKIE_REFRESH_TOKEN_KEY, '/', COOKIE_DOMAIN)
    state.jwtToken = null
    if (Vue.$cookies.isKey(COOKIE_NOWW_SESSION)) Vue.$cookies.remove(COOKIE_NOWW_SESSION, '/', COOKIE_DOMAIN)
  },
  [SET_PASSWORD_EXPIRATION] (state, data) {
    state.passwordExpiresInDays = data
  },
  [SET_TERMS_ACCEPTED] (state, data) {
    state.areTermsAcceptedLatest = data
  },
  [SET_LATEST_RELEASE_SEEN] (state, data) {
    state.areReleaseNotesSeenLatest = data
  }
}

const getters = {
  isAuthenticated: (state, getters) => () => {
    // console.log(`isAuth: ${getters.getCookieToken() && getters.getRefreshToken() && getters.getUserId()}`)
    // console.log(`userId: ${getters.getUserId()}`)
    return getters.getCookieToken() && getters.getRefreshToken() && getters.getUserId()
  },
  getCookieToken: (state, getters) => () => {
    // console.log(`getter keys: ${Vue.$cookies.keys()}`)
    // console.log(`getter isKey token?: ${Vue.$cookies.isKey(COOKIE_TOKEN_KEY)}`)
    // if (Vue.$cookies.isKey(COOKIE_TOKEN_KEY)) console.log(`getter token: ${Vue.$cookies.get(COOKIE_TOKEN_KEY)}`)
    return Vue.$cookies.isKey(COOKIE_TOKEN_KEY) ? Vue.$cookies.get(COOKIE_TOKEN_KEY) : null
  },
  getToken: (state, getters) => () => Vue.$cookies.get(COOKIE_TOKEN_KEY) || state.jwtToken,
  getBearerToken: (state, getters) => () => `Bearer ${state.jwtToken}`,
  getRefreshToken: (state, getters) => () => Vue.$cookies.isKey(COOKIE_REFRESH_TOKEN_KEY) ? Vue.$cookies.get(COOKIE_REFRESH_TOKEN_KEY) : null,
  getUserId: (state, getters) => () => state.userId,
  getUserNowwId: (state, getters) => () => state.userNowwId,
  getCrmUserId: (state, getters) => () => state.crmUserId,
  getCrmContactId: (state, getters) => () => state.crmContactId,
  getUser: (state, getters) => () => ({
    userId: getters.getUserId(),
    userNowwId: getters.getUserNowwId(),
    email: getters.getEmail(),
    firstName: state.firstName,
    lastName: state.lastName,
    tenantId: getters.getTenantId(),
    tenantCrmId: getters.getTenantCrmId(),
    fullName: getters.getFullName(),
    claims: getters.getClaims(),
    roleName: getters.getRole(),
    passwordExpiresInDays: state.passwordExpiresInDays,
    areTermsAcceptedLatest: getters.areTermsAcceptedLatest(),
    areReleaseNotesSeenLatest: getters.areReleaseNotesSeenLatest()
  }),
  getTenantId: (state, getters) => () => state.tenantId,
  getTenantNowwId: (state, getters) => () => state.tenantNowwId,
  getTenantCrmId: (state, getters) => () => state.tenantCrmId,
  getEmail: (state, getters) => () => state.email,
  getFullName: (state, getters) => () => state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : '',
  getRole: (state, getters) => () => {
    let role = state.claims ? state.claims.find(claim => claim.Key === 'Role') : null
    if (!role) return role
    return role.Value
  },
  getRolePackageCode: (state, getters) => () => {
    let role = getters.getRole()
    if (!role) return role
    return role.split('-')[0]
  },
  getRoleTitle: (state, getters) => () => {
    let role = getters.getRole()
    if (!role) return role
    return role.split('-')[1]
  },
  isMMSRole: (state, getters) => () => getters.getRolePackageCode() === 'MMS',
  hasClaim: (state, getters) => (key, val) => {
    return state.claims && state.claims.some(claim => claim.Key === key && claim.Value === val)
  },
  hasClaimKV: (state, getters) => (keyVal) => {
    return getters.hasClaim(keyVal.key, keyVal.value)
  },
  getClaims: (state, getters) => () => state.claims,
  areTermsAcceptedLatest: (state, getters) => () => state.areTermsAcceptedLatest,
  areReleaseNotesSeenLatest: (state, getters) => () => state.areReleaseNotesSeenLatest
}

export default {
  namespaced: true,
  actions,
  mutations,
  state: initialState(),
  getters
}
