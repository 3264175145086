import client, { handleError, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/report'

const getActiveProjects = async function (userId) {
  try {
    const response = await client.get(`${controllerPrefix}/ActiveProjects/${userId}/`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getNPCList = async function (userId) {
  try {
    const response = await client.get(`${controllerPrefix}/InactiveNPC/${userId}/`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const reportService = {
  getActiveProjects,
  getNPCList
}
