import { render, staticRenderFns } from "./MaterialWizard.vue?vue&type=template&id=20fd3eb0&"
import script from "./MaterialWizard.vue?vue&type=script&lang=js&"
export * from "./MaterialWizard.vue?vue&type=script&lang=js&"
import style0 from "./MaterialWizard.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VSpacer,VTab,VTabs,VTabsItems,VTabsSlider})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20fd3eb0')) {
      api.createRecord('20fd3eb0', component.options)
    } else {
      api.reload('20fd3eb0', component.options)
    }
    module.hot.accept("./MaterialWizard.vue?vue&type=template&id=20fd3eb0&", function () {
      api.rerender('20fd3eb0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/MaterialWizard.vue"
export default component.exports