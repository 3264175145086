import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/base'
import './plugins/vuelidate'
import './plugins/vue-cookies'
// import './plugins/vue-froala-wysiwyg'
import './plugins/vue-application-insights'
import './shared/custom-filters'
// import { userInfo } from 'os'
import './plugins/script-loader'

import 'devextreme/dist/css/dx.light.css'

// comment to test building to UAT environment

Vue.config.productionTip = false
Vue.config.silent = false
Vue.use(require('vue-moment'))

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state))
})

Vue.mixin({
  methods: {
    $_global_parseAsUtc (utc) {
      if (!utc || utc === undefined || utc === null) return null
      let _utc = utc.endsWith('Z') ? utc : `${utc}Z`
      return new Date(_utc)
    },
    $_global_hasRouteClaims (routeName, params) {
      let props = this.$router.resolve({
        name: routeName,
        params // { id: some_id, slug: some_slug },
      })
      if (!props.route.meta || !props.route.meta.permission) return true
      return this.$store.getters['auth/hasClaimKV'](props.route.meta.permission)
    }
  }
})

// Vue.directive('blur', {
//   inserted: function (el) {
//     el.onfocus = (ev) => ev.target.blur()
//   }
// })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
