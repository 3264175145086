import client, { parseItem, handleError } from '../axios-config'

const controllerPrefix = '/auth'

const createToken = async function (creds) {
  try {
    const response = await client.post(`${controllerPrefix}/token`, creds)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const refreshTokens = async function (tokens) {
  try {
    const response = await client.post(`${controllerPrefix}/refresh`, tokens)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getRoleClaims = async function (roleName) {
  try {
    const response = await client.get(`${controllerPrefix}/roleClaims/${roleName}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const forgotPassword = async function (email) {
  try {
    const response = await client.post(`${controllerPrefix}/forgotPassword`, email)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const passwordReset = async function (passwordReset) {
  try {
    const response = await client.post(`${controllerPrefix}/passwordReset`, passwordReset)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const authService = {
  createToken,
  refreshTokens,
  getRoleClaims,
  forgotPassword,
  passwordReset
}
