export var splitColors = [
  'orange lighten-1', 'purple lighten-2', 'light-blue', 'pink lighten-3', 'green lighten-2'
]

// Hex values for vue-html2pdf as vuetify color classes are not recognized
export var pdfSplitColors = [
  '#FFA726', '#BA68C8', '#03A9F4', '#F48FB1', '#81C784'
]

export const splitLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
