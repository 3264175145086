const fileIconsList = [
  { ext: 'jpg', icon: 'mdi-file-image' },
  { ext: 'png', icon: 'mdi-file-image' },
  { ext: 'bmp', icon: 'mdi-file-image' },
  { ext: 'gif', icon: 'mdi-video' },
  { ext: 'zip', icon: 'mdi-folder-zip' },
  { ext: 'pdf', icon: 'mdi-file-pdf' },
  { ext: 'htm', icon: 'mdi-file-code' },
  { ext: 'html', icon: 'mdi-file-code' },
  { ext: 'txt', icon: 'mdi-file' },
  { ext: 'csv', icon: 'mdi-file-table' },
  { ext: 'xls', icon: 'mdi-file-table' },
  { ext: 'xlsx', icon: 'mdi-file-table' }
]

function resolveIconByFileName (fileName) {
  let ext = fileName.substr(fileName.lastIndexOf('.') + 1)
  let result = fileIconsList.find(x => x.ext === ext.toLowerCase())
  if (!result) {
    return 'mdi-file'
  }
  return result.icon
}

export const fileIcons = {
  fileIconsList,
  resolveIconByFileName
}
