import client, { parseItem, handleError, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/social'

const linkedInTest = async function () {
  try {
    const response = await client.post(`${controllerPrefix}/li/test`, {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const facebookTest = async function () {
  try {
    const response = await client.post(`${controllerPrefix}/fb/test`, {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const twitterTest = async function () {
  try {
    const response = await client.post(`${controllerPrefix}/tw/test`, {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const socialService = {
  linkedInTest,
  facebookTest,
  twitterTest
}
