import client, { parseItem, handleError, formUploadConfig, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/tenant'

const getContract = async function (contractNumber) {
  try {
    const response = await client.get(`${controllerPrefix}/contract/${contractNumber}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const activateContract = async function (contractNumber) {
  try {
    const response = await client.post(`${controllerPrefix}/contract/activate/${contractNumber}`, null)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getContractClaims = async function (contractId) {
  try {
    const response = await client.get(`${controllerPrefix}/contract/claims/${contractId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const saveContractAddOns = async function ({ contractId, addOns }) {
  try {
    const response = await client.post(`${controllerPrefix}/contract/addOns`, { contractId, addOns })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getPlatformContracts = async function (tenantId) {
  try {
    const response = await client.get(`${controllerPrefix}/platformContracts/${tenantId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getPlatformContractUsage = async function (id) {
  try {
    const response = await client.get(`${controllerPrefix}/contract/usage/${id}`, null)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getActivePlatformContract = async function (nowwId) {
  try {
    const response = await client.get(`${controllerPrefix}/platformContract/${nowwId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUser = async function (userId, token) {
  try {
    let response = null
    if (token) {
      response = await client.get(`${controllerPrefix}/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } else {
      response = await client.get(`${controllerPrefix}/user/${userId}`)
    }
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createUser = async function (user) {
  try {
    const response = await client.post(`${controllerPrefix}/user`, user)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateUser = async function (user) {
  try {
    const response = await client.put(`${controllerPrefix}/user`, user, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteUser = async function (userId) {
  try {
    const response = await client.delete(`${controllerPrefix}/user/${userId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const lockUnlockUsers = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/users/lock`, data, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUsers = async function (tenantId) {
  try {
    const response = await client.get(`${controllerPrefix}/users/${tenantId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUsersByTenantCrm = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/users/crmId/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUsersLockedByTenantCrm = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/usersLocked/crmId/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getContactsByTenantCrm = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/contacts/crmId/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getAllUsers = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/users`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const passwordChange = async function (passwords) {
  try {
    const response = await client.post(`${controllerPrefix}/passwordChange`, passwords)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTermsAndConditions = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/termsAndConditions`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTenants = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/tenants`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTenantByCrmId = async function (crmId) {
  try {
    const response = await client.get(`${controllerPrefix}/byCrmId/${crmId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getAccountByCrmId = async function (crmId) {
  try {
    const response = await client.get(`${controllerPrefix}/details/byCrmId/${crmId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOtherTenants = async function (search) {
  try {
    const response = await client.get(`${controllerPrefix}/otherTenants/${search}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createTenantFromCrm = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/createTenantFromCrm`, data, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getInvoices = async function (contractId) {
  try {
    const response = await client.get(`${controllerPrefix}/getInvoices/${contractId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getInvoicePDF = async function (invoiceId) {
  try {
    const response = await client.get(`${controllerPrefix}/invoicePDF/${invoiceId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const getAcousticLoginsList = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/acousticLogin/list`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSharedLoginsList = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/sharedLogin/list`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const assignAcousticLogin = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/acousticLogin/assign`, data, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOptOutList = async function (crmId) {
  try {
    const response = await client.get(`${controllerPrefix}/optOut/list/${crmId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createOptOut = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/optOut/create`, data, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setOptOutValue = async function (optOutId, prop, value) {
  try {
    const response = await client.post(`${controllerPrefix}/optOut/${prop}`, { id: optOutId, value: value !== undefined && value !== null ? value.toString() : value }, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadOptOutLogo = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/optOut/uploadLogo`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteOptOut = async function (optOutId) {
  try {
    const response = await client.delete(`${controllerPrefix}/optOut/delete/${optOutId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const optInDefaultTextVersionCreative = async function (tenant) {
  try {
    const response = await client.post(`${controllerPrefix}/optInDefaultTextVersionCreative`, tenant, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const provideFeedback = async function (feedback) {
  try {
    const response = await client.post(`${controllerPrefix}/help/feedback`, feedback)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getFeedbackList = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/help/feedback/list`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const reportBug = async function (bug) {
  try {
    const response = await client.post(`${controllerPrefix}/help/bug`, bug)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getBugReportList = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/help/bug/list`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getIsSendingDomain = async function (sendingDomain) {
  try {
    const response = await client.get(`${controllerPrefix}/getIsDomainShared/${sendingDomain}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const tenantService = {
  getContract,
  activateContract,
  getContractClaims,
  saveContractAddOns,
  getPlatformContracts,
  getPlatformContractUsage,
  getActivePlatformContract,
  getUsers,
  getUsersByTenantCrm,
  getUsersLockedByTenantCrm,
  getContactsByTenantCrm,
  getAllUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  lockUnlockUsers,
  passwordChange,
  getTermsAndConditions,
  getTenants,
  getTenantByCrmId,
  getAccountByCrmId,
  getOtherTenants,
  createTenantFromCrm,
  getInvoices,
  getInvoicePDF,
  getAcousticLoginsList,
  getSharedLoginsList,
  assignAcousticLogin,
  getOptOutList,
  createOptOut,
  setOptOutValue,
  uploadOptOutLogo,
  deleteOptOut,
  optInDefaultTextVersionCreative,
  provideFeedback,
  getFeedbackList,
  reportBug,
  getBugReportList,
  getIsSendingDomain
}
