import Vue from 'vue'
import moment from 'moment'
import constants from './constants'
import startCase from 'lodash/startCase'

Vue.filter('pascalToSpace', function (value) {
  if (!value) return ''
  return value.toString().replace(/([A-Z])/g, ' $1').trim()
})

Vue.filter('camelToSpace', function (value) {
  if (!value) return ''
  return startCase(value)
})

Vue.filter('titleCase', function (value) {
  if (!value) return ''
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
})

Vue.filter('upperCase', function (value) {
  if (!value) return ''
  return value.toString().toUpperCase()
})

Vue.filter('ellipses', function (value) {
  if (!value) return ''
  return `${value.toString()}...`
})

Vue.filter('truncate', function (value, limit) {
  if (!value) return ''
  if (value.length > limit) {
    value = `${value.substring(0, (limit - 3))}...`
  }
  return value
})

Vue.filter('phone', function (phone) {
  if (!phone) return ''
  return phone.replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
})

Vue.filter('money', function (value) {
  if (!value) return '0.00'
  return '$' + parseFloat(value).toFixed(2)
})

Vue.filter('percent', function (value, decimals = 2) {
  if (!value) return parseFloat('0').toFixed(decimals) + '%'
  return parseFloat(value).toFixed(decimals) + '%'
})

Vue.filter('commaNumber', function (value) {
  if (!value) return '0'
  return Number(value).toLocaleString()
})

Vue.filter('inputDateFormat', function (date) {
  if (!date) return ''
  return moment(date).format(constants.inputDateFormat)
})

Vue.filter('displayDateFormat', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayDateFormat)
})

Vue.filter('displayDateFormat2', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayDateFormat2)
})

Vue.filter('displayDateFormat3', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayDateFormat3)
})

Vue.filter('displayTimeFormat', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayTimeFormat)
})

Vue.filter('displayTimeFormat2', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayTimeFormat2)
})

Vue.filter('displayTimeFormat3', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayTimeFormat3)
})

Vue.filter('displayDateTimeFormat', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayDateTimeFormat)
})

Vue.filter('displayDateTimeFormat2', function (date) {
  if (!date) return ''
  return moment(date).format(constants.displayDateTimeFormat2)
})

Vue.filter('pretty', function (value) {
  if (!value) return ''
  return JSON.stringify(value, null, 2)
})
