import client, { parseItem, handleError, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/userState'

const getUserState = async function (userId, token) {
  try {
    let response = null
    if (token) {
      response = await client.get(`${controllerPrefix}/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } else {
      response = await client.get(`${controllerPrefix}/${userId}`)
    }
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateDarkMode = async function (isDarkMode) {
  try {
    const response = await client.post(`${controllerPrefix}/darkMode`, isDarkMode, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateViewAsCustomer = async function (customerId) {
  try {
    const response = await client.post(`${controllerPrefix}/viewAsCustomer`, customerId, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateFilterUserNowwId = async function (filterUserNowwId) {
  try {
    const response = await client.post(`${controllerPrefix}/filterUserNowwId`, filterUserNowwId, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateDashboardViewMode = async function (dashboardViewMode) {
  try {
    const response = await client.post(`${controllerPrefix}/dashboardViewMode`, dashboardViewMode, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateCalendarFocusDate = async function (calendarFocusDate) {
  try {
    const response = await client.post(`${controllerPrefix}/calendarFocusDate`, calendarFocusDate, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateCalendarViewType = async function (calendarViewType) {
  try {
    const response = await client.post(`${controllerPrefix}/calendarViewType`, calendarViewType, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateDaysFromNow = async function (daysFromNow) {
  try {
    const response = await client.post(`${controllerPrefix}/daysFromNow`, daysFromNow, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateOrderTypeFilter = async function (orderType) {
  try {
    const response = await client.post(`${controllerPrefix}/orderTypeFilter`, orderType, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateFileTypeFilter = async function (fileType) {
  try {
    const response = await client.post(`${controllerPrefix}/fileTypeFilter`, fileType, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateTAStatus = async function (taStatus) {
  try {
    const response = await client.post(`${controllerPrefix}/taStatus`, taStatus, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateCampaignStatus = async function (campaignStatus) {
  try {
    const response = await client.post(`${controllerPrefix}/campaignStatus`, campaignStatus, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const acceptTerms = async function (userState) {
  try {
    const response = await client.post(`${controllerPrefix}/acceptTerms`, userState, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const seeLatestReleaseNotes = async function (userState) {
  try {
    const response = await client.post(`${controllerPrefix}/seeReleaseNotes`, userState, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateReportWidgets = async function (reportWidgets) {
  try {
    const response = await client.post(`${controllerPrefix}/reportWidgets`, reportWidgets, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const userHistory = async function (userId) {
  try {
    const response = await client.get(`${controllerPrefix}/userHistory/${userId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const usersReport = async function (tenantId) {
  try {
    const response = await client.post(`${controllerPrefix}/usersReport/${tenantId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const userService = {
  getUserState,
  updateDarkMode,
  updateViewAsCustomer,
  updateFilterUserNowwId,
  updateDashboardViewMode,
  updateCalendarFocusDate,
  updateCalendarViewType,
  updateDaysFromNow,
  updateOrderTypeFilter,
  updateFileTypeFilter,
  updateTAStatus,
  updateCampaignStatus,
  acceptTerms,
  seeLatestReleaseNotes,
  updateReportWidgets,
  userHistory,
  usersReport
}
