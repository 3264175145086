import { mailingService, contactService, nowwService, azBlobService } from '@/shared/services'
import { splitLetters } from '@/shared/models/mailing'
import Vue from 'vue'

function initialState () {
  return {
    personalizations: [],
    campaignId: null,
    tenantCrmId: null,
    mailingItemId: null,
    targetId: null,
    optOutId: null,
    campaignNumberOfBroadcasts: 1,
    campaignTenantName: null,
    campaignName: null,
    campaignOnBehalfOfNowwId: null,
    campaignCount: 0,
    campaignFileType: 'AMA',
    campaignFileTypeId: 1,
    campaignNowwOrderNumber: null,
    campaignStatus: 1,
    targetAudienceStatus: null,
    optInDefaultTextVersionCreative: true,
    mailingName: null,
    mailingStatus: 1,
    winningDistribution: 0,
    winningCriteria: null,
    scheduleDate: null,
    winningScheduleDate: null,
    prevScheduleDate: null,
    maxScheduleDate: null,
    sequence: null,
    emailConfigOn: null,
    testedOn: null,
    scheduledOn: null,
    sentOn: null,
    splits: [],
    resendType: null,
    globalResends: [],
    contacts: [],
    deviceSizes: []
  }
}

const actions = {

  async setMailing ({ commit, dispatch, getters }, { mailingItemId, noLoad = false }) {
    commit('reset')
    commit('setLoadingMessage', 'Loading Mailing', { root: true })
    const data = await mailingService.getMailing(mailingItemId, noLoad)
    if (data.errorResponse) return data
    data.splits.forEach((item, index) => {
      Vue.set(item, 'textAnalysis', { loading: false, reloaded: false })
      Vue.set(item, 'spam', { loading: true })
      Vue.set(item, 'links', { loading: true })
      Vue.set(item, 'htmlErrors', [])
      Vue.set(item, 'htmlWarnings', [])
      Vue.set(item, 'isValid', true)
      Vue.set(item, 'devicePreview', false)
      Vue.set(item, 'dragAndDrop', false)
      Vue.set(item, 'dragAndDropExpanded', [0, 1, 2])
      Vue.set(item, 'textMobileDevicePreview', false)
      Vue.set(item, 'deviceWidth', 0)
    })
    commit('setMailing', data)
    commit('setCampaign', data)

    for (let i in data.splits) {
      commit('setLoadingMessage', `Loading Split ${splitLetters[i]}`, { root: true })
      await dispatch('getSplitHtml', { splitIndex: i, noLoad })
    }

    return data
  },

  async setCampaign ({ commit, dispatch, getters }, campaignId) {
    commit('setLoadingMessage', 'Loading Campaign', { root: true })
    const data = await mailingService.getCampaign(campaignId)
    if (data.errorResponse) return data
    commit('setCampaign', data)

    return data
  },

  async loadContacts ({ commit, dispatch, getters }, mic) {
    commit('setLoadingMessage', 'Loading Contacts', { root: true })
    const data = await contactService.getContacts(mic, mic.noLoad)
    if (data.errorResponse) return data
    data.forEach((item, index) => {
      item.personalizations.forEach((pers, index2) => {
        item[pers.name.toLowerCase()] = pers.value
      })
    })
    commit('setContacts', data)
    return data
  },

  async createSplit ({ commit, dispatch, getters }, split) {
    split.mailingItemId = getters['getMailingItemId']()
    const data = await mailingService.createSplit(split)
    if (data.errorResponse) return data
    split.id = data
    Vue.set(split, 'textAnalysis', { loading: false, reloaded: false })
    Vue.set(split, 'spam', { loading: true })
    Vue.set(split, 'links', { loading: true })
    Vue.set(split, 'htmlErrors', [])
    Vue.set(split, 'htmlWarnings', [])
    Vue.set(split, 'isValid', true)
    Vue.set(split, 'devicePreview', false)
    Vue.set(split, 'dragAndDrop', false)
    Vue.set(split, 'dragAndDropExpanded', [0, 1, 2])
    Vue.set(split, 'textMobileDevicePreview', false)
    Vue.set(split, 'deviceWidth', 0)
    commit('createSplit', split)
    await dispatch('getSplitHtml', { splitIndex: getters['getSplits']().length - 1 })
    return split
  },

  async deleteSplit ({ commit, dispatch, getters }, splitIndex) {
    let splitId = getters['getSplit'](splitIndex).id
    const data = await mailingService.deleteSplit(splitId)
    if (data.errorResponse) return data
    commit('deleteSplit', splitIndex)
    return splitId
  },

  async testSplit ({ commit, dispatch, getters }, splitIndex) {
    let splitId = getters['getSplit'](splitIndex).id
    commit('setLoadingMessage', `Testing Split ${splitLetters[splitIndex]}`, { root: true })
    const data = await mailingService.testSplit(splitId)
    if (data.errorResponse) return data
    commit('setSplitValue', { index: splitIndex, prop: 'testedOn', value: data })
    commit('setGlobalSnackbar', { message: `Successfully tested Split ${splitLetters[splitIndex]}`, color: 'success' }, { root: true })
    return data
  },

  async testMailing ({ commit, dispatch, getters }, mailingItemId) {
    commit('setLoadingMessage', 'Testing Mailing', { root: true })
    const data = await mailingService.testMailing(mailingItemId)
    if (data.errorResponse) return data
    getters['getSplits']().forEach((split, i) => {
      commit('setSplitValue', { index: i, prop: 'testedOn', value: data })
    })
    commit('setGlobalSnackbar', { message: `Successfully tested mailing`, color: 'success' }, { root: true })
    return data
  },

  async getSplitHtml ({ commit, dispatch, getters }, { splitIndex, noLoad = false, updateText = false }) {
    let html = await azBlobService.getSplitHtml(getters['getSplit'](splitIndex).id, noLoad)
    if (html.errorResponse) return html

    if (updateText) {
      if (getters['getOptInDefaultTextVersionCreative']()) {
        await mailingService.parseCreativeText(getters['getSplit'](splitIndex).id)
          .then(async (resp) => {
            if (!resp.errorResponse) {
              await dispatch('setSplitValue', { index: splitIndex, prop: 'textAccessibility', value: resp })
            } else {
              await dispatch('setSplitValue', { index: splitIndex, prop: 'textAccessibility', value: '' })
            }
          })
      } else {
        await dispatch('setSplitValue', { index: splitIndex, prop: 'textAccessibility', value: '' })
      }
    }

    commit('setSplitHtml', { id: getters['getSplit'](splitIndex).id, html })
    await dispatch('validateSplit', { index: splitIndex, noLoad })
  },

  async validateSplit ({ commit, dispatch, getters }, { index, noLoad = false }) {
    let resp = await mailingService.validateHtml(getters['getSplit'](index).id, noLoad)
    if (resp.errorResponse) return resp
    commit('setSplitValue', { index, prop: 'htmlErrors', value: resp.errors, forceRefresh: true })
    commit('setSplitValue', { index, prop: 'htmlWarnings', value: resp.warnings, forceRefresh: true })
    commit('setSplitValue', { index, prop: 'isValid', value: resp.isValid, forceRefresh: true })
  },

  async setMailingStepValue ({ commit, dispatch, getters }, { prop, flag }) {
    const data = await mailingService.setMailingValue(getters['getMailingItemId'](), prop, flag)
    if (data.errorResponse) return data
    commit('setMailingValue', { prop, value: data })
  },

  async setCampaignValue ({ commit, dispatch, getters }, { prop }) {
    const data = await mailingService.setCampaignValue(getters['getCampaignId'](), prop, getters[`get${prop.charAt(0).toUpperCase() + prop.substring(1)}`]())
    if (data.errorResponse) return data
  },

  async setMailingValue ({ commit, dispatch, getters }, { prop }) {
    const data = await mailingService.setMailingValue(getters['getMailingItemId'](), prop, getters[`get${prop.charAt(0).toUpperCase() + prop.substring(1)}`]())
    if (data.errorResponse) return data
  },

  async setSplitValue ({ commit, dispatch, getters }, { index, prop, value }) {
    const data = await mailingService.setSplitValue(getters['getSplit'](index).id, prop, value)
    if (data.errorResponse) return data
    commit('setSplitValue', { index, prop, value, forceRefresh: false })
    commit('setSplitValue', { index, prop: 'modifiedOn', value: data })
  },

  async setDefaultPersonalizationValue ({ commit, dispatch, getters }, personalizationMeta) {
    commit('setDefaultPersonalizationValue', personalizationMeta)
    const data = await contactService.updatePersonalizationMeta(personalizationMeta)
    if (data.errorResponse) return data
  },

  async copyTemplatesHTMLToSplit ({ commit, dispatch, getters }, data) {
    const resp = await azBlobService.copyTemplatesHTMLToSplit({ azLocation: data.copyFromPath, splitId: getters['getSplit'](data.index).id })
    if (resp.errorResponse) return resp
    commit('setSplitValue', { index: data.index, prop: 'azHtmlLocation', value: resp.filePath })
    commit('setSplitValue', { index: data.index, prop: 'azHtmlFolder', value: resp.folder })

    await dispatch('getSplitHtml', { splitIndex: data.index, updateText: true })
  },

  async copyCLHtmlToSplit ({ commit, dispatch, getters }, data) {
    const resp = await azBlobService.copyCLHtmlToSplit({ azLocation: data.copyFromPath, splitId: getters['getSplit'](data.index).id })
    if (resp.errorResponse) return resp
    commit('setSplitValue', { index: data.index, prop: 'azHtmlLocation', value: resp.filePath })
    commit('setSplitValue', { index: data.index, prop: 'azHtmlFolder', value: resp.folder })

    await dispatch('getSplitHtml', { splitIndex: data.index, updateText: true })
  },

  async saveSplitHtml ({ commit, dispatch, getters }, data) {
    const req = {
      html: data.html,
      splitId: getters['getSplit'](data.index).id
    }
    const resp = await azBlobService.saveSplitHtml(req)
    if (resp.errorResponse) return resp

    commit('setSplitHtmlNoRefresh', { id: req.splitId, html: req.html })
    commit('setSplitValue', { index: data.index, prop: 'modifiedOn', value: resp.modifiedOn })
    return resp
  },

  async removeContactFromMailing ({ commit, dispatch, getters }, mic) {
    const resp = await contactService.removeContact(mic)
    if (resp.errorResponse) return resp
    commit('removeContact', mic)
  },

  async addContactToMailing ({ commit, dispatch, getters }, contact) {
    let nonPersonalizationFields = ['mailingItemId', 'contactId', 'email', 'fname', 'lname', 'name', 'mddo', 'receiveTest', 'receiveFinalBroadcast']
    let contactPersonalizations = []

    for (let pers in contact) {
      if (!contact.hasOwnProperty(pers)) continue // skip prototype fields
      if (nonPersonalizationFields.includes(pers)) continue
      contactPersonalizations.push({
        personalizationMetaId: getters['getPersonalizationByName'](pers.replace(/_/g, ' ')).id,
        contactId: contact.contactId,
        mailingItemId: contact.mailingItemId,
        name: pers.replace(/_/g, ' ').toUpperCase(),
        value: contact[pers]
      })
    }

    contact.personalizations = contactPersonalizations

    const resp = await contactService.addContact(contact)
    if (resp.errorResponse) return resp
    for (let pers in contact) {
      if (!contact.hasOwnProperty(pers)) continue // skip prototype fields
      if (nonPersonalizationFields.includes(pers)) continue
      resp[pers.replace(/_/g, ' ').toLowerCase()] = contact[pers]
    }
    commit('setContact', resp)

    return resp
  },

  async setContactPersonalizations ({ commit, dispatch, getters }, contact) {
    contact.personalizations.forEach((pers) => {
      pers.value = contact[pers.name.toLowerCase()]
    })
    const resp = await contactService.updateContactPersonalizations(contact)
    if (resp.errorResponse) return resp
    commit('setContact', contact)
  },
}

const mutations = {
  reset (state) { // https://github.com/vuejs/vuex/issues/1118
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  setMailing (state, data) {
    state.personalizations = data.personalizations
    state.mailingItemId = data.id
    state.optOutId = data.optOutId
    state.mailingName = data.name
    state.mailingStatus = data.status
    state.winningDistribution = data.winningDistribution
    state.winningCriteria = data.winningCriteria
    state.scheduleDate = data.scheduleDate
    state.winningScheduleDate = data.winningScheduleDate
    state.prevScheduleDate = data.prevScheduleDate
    state.maxScheduleDate = data.maxScheduleDate
    state.sequence = data.sequence
    state.emailConfigOn = data.emailConfigOn
    state.testedOn = data.testedOn
    state.scheduledOn = data.scheduledOn
    state.sentOn = data.sentOn
    state.splits = data.splits.slice()
    state.resendType = data.resendType || null
    state.globalResends = data.globalResends || []
  },
  setCampaign (state, data) {
    state.campaignId = data.campaignId
    state.tenantCrmId = data.tenantCrmId
    state.campaignNumberOfBroadcasts = data.campaignNumberOfBroadcasts
    state.campaignTenantName = data.campaignTenantName
    state.campaignName = data.campaignName
    state.campaignOnBehalfOfNowwId = data.campaignOnBehalfOfNowwId
    state.campaignCount = data.campaignCount
    state.campaignFileTypeId = data.campaignFileTypeId
    state.campaignFileType = data.campaignFileType
    state.campaignNowwOrderNumber = data.campaignNowwOrderNumber
    state.campaignStatus = data.campaignStatus
    state.targetAudienceStatus = data.targetAudienceStatus
    state.targetId = data.targetAudienceId
    state.optInDefaultTextVersionCreative = data.optInDefaultTextVersionCreative
  },
  setContacts (state, data) {
    state.contacts = data
  },
  setContact (state, data) {
    let match = state.contacts.find(x => x.contactId === data.contactId)
    if (!match) {
      state.contacts.push(data)
    } else {
      Object.assign(state.contacts[state.contacts.indexOf(match)], data)
    }
  },
  removeContact (state, data) {
    const item = state.contacts.find(x => x.contactId === data.contactId)
    const index = state.contacts.indexOf(item)
    state.contacts.splice(index, 1)
  },
  setCampaignValue (state, data) {
    Vue.set(state, data.prop, data.value || null)
  },
  setMailingValue (state, data) {
    Vue.set(state, data.prop, data.value || null)
  },
  createSplit (state, data) {
    state.splits.push(data)
  },
  deleteSplit (state, data) {
    state.splits.splice(data, 1)
  },
  setSplits (state, data) {
    // state.splits = data.splits.slice()
    Vue.set(state, 'splits', data.splits.slice())
  },
  setSplitValue (state, data) {
    // console.log(`splits[${data.index}].${data.prop} - ${data.value}`)
    // state.splits[data.index][data.prop] = data.value
    Vue.set(state.splits[data.index], data.prop, data.value)
    if (data.forceRefresh === true) {
      state.splits.push({})
      state.splits.pop()
    }
  },
  setSplitHtml (state, data) {
    var split = state.splits.find(x => x.id === data.id)
    Vue.set(split, 'html', data.html)
    state.splits.push({})
    state.splits.pop()
  },
  setSplitHtmlNoRefresh (state, data) {
    var split = state.splits.find(x => x.id === data.id)
    Vue.set(split, 'html', data.html)
  },
  setDefaultPersonalizationValue (state, data) {
    let meta = state.personalizations.find(x => x.id === data.id)
    state.contacts.forEach((contact, index) => {
      if (contact[meta.name.toLowerCase().replace(/_/g, ' ')] === meta.testValue) {
        contact[meta.name.toLowerCase().replace(/_/g, ' ')] = data.testValue
        let pers = contact.personalizations.find(x => x.personalizationMetaId === data.id)
        pers.value = data.testValue
      }
    })
    meta.testValue = data.testValue
  },

}

const getters = {
  getPersonalizations: (state, getters) => () => state.personalizations || [],
  getPersonalizationByName: (state, getters) => (name) => getters.getPersonalizations().find(x => x.name.toLowerCase() === name.toLowerCase()),
  getCampaignId: (state, getters) => () => state.campaignId,
  getMailingTenantCrmId: (state, getters) => () => state.tenantCrmId,
  getMailingItemId: (state, getters) => () => state.mailingItemId,
  getTargetId: (state, getters) => () => state.targetId,
  getOptOutId: (state, getters) => () => state.optOutId,
  getCampaignNumberOfBroadcasts: (state, getters) => () => state.campaignNumberOfBroadcasts,
  getCampaignTenantName: (state, getters) => () => state.campaignTenantName,
  getCampaignName: (state, getters) => () => state.campaignName,
  getCampaignOnBehalfOfNowwId: (state, getters) => () => state.campaignOnBehalfOfNowwId,
  getCampaignCount: (state, getters) => () => state.campaignCount,
  getCampaignFileTypeId: (state, getters) => () => state.campaignFileTypeId,
  getCampaignFileType: (state, getters) => () => state.campaignFileType,
  getCampaignNowwOrderNumber: (state, getters) => () => state.campaignNowwOrderNumber,
  getCampaignStatus: (state, getters) => () => state.campaignStatus,
  getTargetAudienceStatus: (state, getters) => () => state.targetAudienceStatus,
  getOptInDefaultTextVersionCreative: (state, getters) => () => state.optInDefaultTextVersionCreative,
  getMailingName: (state, getters) => () => state.mailingName,
  getMailingStatus: (state, getters) => () => state.mailingStatus,
  getWinningCriteria: (state, getters) => () => state.winningCriteria,
  getWinningDistribution: (state, getters) => () => state.winningDistribution,
  getScheduleDate: (state, getters) => () => state.scheduleDate,
  getWinningScheduleDate: (state, getters) => () => state.winningScheduleDate,
  getPrevScheduleDate: (state, getters) => () => state.prevScheduleDate,
  getMaxScheduleDate: (state, getters) => () => state.maxScheduleDate,
  getEmailConfigOn: (state, getters) => () => state.emailConfigOn,
  getTestedOn: (state, getters) => () => state.testedOn,
  getScheduledOn: (state, getters) => () => state.scheduledOn,
  getSentOn: (state, getters) => () => state.sentOn,
  getSplits: (state, getters) => () => state.splits,
  getSplit: (state, getters) => (index) => state.splits[index],
  getSplitIsTested: (state, getters) => (index) => state.splits[index].testedOn && new Date(state.splits[index].testedOn) >= new Date(state.splits[index].modifiedOn),
  getContacts: (state, getters) => () => state.contacts,
  getSequence: (state, getters) => () => state.sequence,
  getIsResend: (state, getters) => () => state.sequence !== 1,
  getResendType: (state, getters) => () => state.resendType,
  getGlobalResends: (state, getters) => () => state.globalResends
}

export default {
  namespaced: true,
  actions,
  mutations,
  state: initialState(),
  getters
}
