







import Vue from 'vue'
import { INITIALIZE_STORE } from '@/store/mutation-types'

export default Vue.extend({
  name: 'App',
  components: {
    CoreLoadingOverlay: () => import('@/components/layout/core/CoreLoadingOverlay.vue')
  },
  beforeCreate () {
    this.$store.commit(INITIALIZE_STORE)
  }
})
