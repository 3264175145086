var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "display-2 font-weight-light col col-12 text-left text--primary pa-0 mb-8"
    },
    [
      _c(
        "h5",
        { staticClass: "font-weight-light" },
        [
          _vm._v("\n    " + _vm._s(_vm.subheading) + "\n    "),
          _vm.text
            ? [
                _c("span", {
                  staticClass: "text-subtitle-1",
                  domProps: { textContent: _vm._s(_vm.text) }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "pt-2" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }