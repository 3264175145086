import Vue from 'vue'
import Router from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router' // for vue 3 & vue-router@4
import store from '@/store'
import claims from '@/shared/models/auth/claims'
import {
  DefaultForbiddenMessage,
  DefaultUnauthenticatedMessage
} from '@/shared/axios-config'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/components/layout/core/CoreIndex.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          redirect: {
            name: 'AppLogin'
          }
        },
        {
          path: 'dashboard/targetAudience',
          name: 'TADashboard',
          component: () => import('@/views/pages/dashboards/TADashboard.vue'),
          meta: {
            title: 'Target Audiences'
          }
        },
        {
          path: 'dashboard/campaign',
          name: 'CampaignDashboard',
          component: () => import('@/views/pages/dashboards/CampaignDashboard.vue'),
          meta: {
            title: 'Campaigns',
            permission: claims.BS_TB,
            permissionRedirect: { name: 'TADashboard' }
          }
        },
        {
          path: 'dashboard/csmDashboard',
          name: 'CsmDashboard',
          component: () => import('@/views/pages/dashboards/CsmDashboard.vue'),
          meta: {
            title: 'CSM Dashboard',
            permission: claims.MMS_TEBT
          }
        },
        {
          path: 'campaign/mailing/:mailingItemId',
          name: 'MailingCampaign',
          props: true,
          component: () => import('@/views/pages/mailing/MailingCampaign.vue')
        },
        {
          path: 'campaign/history/:campaignId',
          name: 'CampaignHistory',
          props: true,
          component: () => import('@/views/pages/mailing/CampaignHistory.vue')
        },
        {
          path: 'campaign/mailing/results/:campaignId/:mailingItemId',
          name: 'MailingResultsDashboard',
          props: true,
          component: () => import('@/views/pages/mailing/MailingResultsDashboard.vue'),
          meta: {
            title: 'Real Time Dashboard',
            permission: claims.R_SER
          }
        },
        {
          path: 'targetAudience/:targetAudienceId',
          name: 'TargetAudience',
          props: true,
          component: () => import('@/views/pages/ta/TargetAudience.vue'),
          meta: {
            permission: claims.MMS_TC
          }
        },
        {
          path: 'targetData',
          name: 'TargetData',
          component: () => import('@/views/pages/target-data/TargetData.vue'),
          meta: {
            title: 'Import Target Data',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'targetDataImport',
          name: 'TargetDataImport',
          component: () => import('@/views/pages/target-data/TargetDataImport.vue'),
          meta: {
            title: 'Import Target Data',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'importTargetFile',
          name: 'ImportTargetFile',
          component: () => import('@/views/pages/target-data/ImportTargetFile.vue'),
          meta: {
            title: 'Import Target File',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'suppression/emailSuppression',
          name: 'EmailSuppression',
          component: () => import('@/views/pages/suppression/EmailSuppression.vue'),
          meta: {
            title: 'Import Email Suppression List',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'suppression/globalSuppression',
          name: 'GlobalSuppression',
          component: () => import('@/views/pages/suppression/GlobalSuppression.vue'),
          meta: {
            title: 'Import Global Suppression List',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'emailSuppressionImport',
          name: 'EmailSuppressionImport',
          component: () => import('@/views/pages/suppression/EmailSuppressionImport.vue'),
          meta: {
            title: 'Import Email Suppression',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'globalSuppressionImport',
          name: 'GlobalSuppressionImport',
          component: () => import('@/views/pages/suppression/GlobalSuppressionImport.vue'),
          meta: {
            title: 'Import Global Suppression',
            permission: claims.ITA_TFM
          }
        },
        {
          path: 'drLookup',
          name: 'DrLookup',
          component: () => import('@/views/pages/dashboards/DrLookup.vue'),
          meta: {
            title: 'Dr Lookup',
            permission: claims.ITA_PL
          }
        },
        // {
        //   path: 'reports/classic',
        //   name: 'ClassicReports',
        //   component: () =>
        //     import('@/views/pages/reports/ClassicReports.vue'),
        //   meta: {
        //     permission: claims.ITA_CLR
        //   }
        // },
        {
          path: 'reports/singlemailing/:campaignId/:mailingItemId',
          name: 'SingleMailingReport',
          props: true,
          component: () =>
            import('@/views/pages/reports/SingleMailingReport.vue'),
          meta: {
            permission: claims.R_SER
          }
        },
        // {
        //   path: 'reports/campaign/combined',
        //   name: 'CombinedCampaignReport',
        //   component: () =>
        //     import('@/views/pages/reports/CombinedCampaignReport.vue'),
        //   meta: {
        //     permission: claims.MMS_TM // TODO: when done, use - claims.R_SER instead
        //   }
        // },
        {
          path: 'reports/campaign/:campaignId',
          name: 'CampaignReport',
          props: true,
          component: () =>
            import('@/views/pages/reports/CampaignReport.vue'),
          meta: {
            permission: claims.R_SER
          }
        },
        {
          path: 'reports/campaignHeatmap/:campaignId',
          name: 'CampaignEventsHeatmap',
          props: true,
          component: () =>
            import('@/views/pages/reports/CampaignEventsHeatmap.vue'),
          meta: {
            title: 'Campaign Events Heatmap',
            permission: claims.R_BIH
          }
        },
        {
          path: 'reports/serviceLine/specialty',
          name: 'ServiceLineSpecialtyReport',
          component: () =>
            import('@/views/pages/reports/ServiceLineSpecialtyReport.vue'),
          meta: {
            permission: claims.R_SLSR
          }
        },
        {
          path: 'reports/serviceLine/offerType',
          name: 'ServiceLineOfferTypeReport',
          component: () =>
            import('@/views/pages/reports/ServiceLineOfferTypeReport.vue'),
          meta: {
            permission: claims.R_SLOTR
          }
        },
        {
          path: 'reports/specialtyEngagement',
          name: 'SpecialtyEngagementReport',
          component: () =>
            import('@/views/pages/reports/SpecialtyEngagementReport.vue'),
          meta: {
            permission: claims.R_SPER
          }
        },
        {
          path: 'reports/dashboard',
          name: 'ReportDashboard',
          props: true,
          component: () =>
            import('@/views/pages/reports/ReportDashboard.vue')
        },
        {
          path: 'reports/dashboardCompare',
          name: 'DashboardCompare',
          props: true,
          component: () =>
            import('@/views/pages/reports/DashboardCompare.vue'),
          meta: {
            title: 'Engagement Comparison',
            permission: claims.R_ECR
          }
        },
        {
          path: 'reports/topPerformers',
          name: 'TopPerformersReport',
          props: true,
          component: () => import('@/views/pages/reports/TopPerformersReport.vue'),
          meta: {
            title: 'Top Performers',
            permission: claims.R_TBPR
          }
        },
        {
          path: 'reports/bestDay',
          name: 'BestDayReport',
          props: true,
          component: () => import('@/views/pages/reports/BestDayReport.vue'),
          meta: {
            title: 'Best Day Open and Received',
            permission: claims.R_BDR
          }
        },
        {
          path: 'contentLibrary/images',
          name: 'ContentLibraryImages',
          component: () =>
            import('@/views/pages/content-library/ContentLibraryImages.vue'),
          meta: {
            permission: claims.CL_CLM,
            title: 'Images'
          }
        },
        {
          path: 'contentLibrary/creatives',
          name: 'ContentLibraryHtml',
          component: () =>
            import('@/views/pages/content-library/ContentLibraryHtml.vue'),
          meta: {
            permission: claims.CL_CLM,
            title: 'Creatives'
          }
        },
        {
          path: 'contentLibrary/dataFeed',
          name: 'ContentLibraryDataFeed',
          component: () =>
            import('@/views/pages/content-library/ContentLibraryDataFeed.vue'),
          meta: {
            permission: claims.CL_DF
          }
        },
        {
          path: 'admin',
          name: 'Admin',
          component: () => import('@/views/pages/admin/Admin.vue'),
          meta: {
            title: 'Administrative module',
            permission: claims.MMS_TM
          }
        },
        {
          path: 'processOrder',
          name: 'ProcessOrder',
          meta: {
            title: 'Process Order',
            permission: claims.MMS_TM
          },
          component: () => import('@/views/pages/admin/ProcessOrder.vue')
        },
        {
          path: 'omitCodes',
          name: 'OmitCodes',
          meta: {
            title: 'Omit Codes',
            permission: claims.MMS_TM
          },
          component: () => import('@/views/pages/admin/OmitCodes.vue')
        },
        {
          path: 'autoProcessOrder',
          name: 'AutoProcessOrder',
          meta: {
            title: 'Auto Process Order',
            permission: claims.MMS_TM
          },
          component: () => import('@/views/pages/admin/AutoProcessOrder.vue')
        },
        {
          path: 'downloadProcessedJobs',
          name: 'DownloadProcessedJobs',
          meta: {
            title: 'Download Processed Jobs',
            permission: claims.MMS_TM
          },
          component: () => import('@/views/pages/admin/DownloadProcessedJobs.vue')
        },
        {
          path: 'campaignAdmin',
          name: 'CampaignAdmin',
          component: () => import('@/views/pages/admin/CampaignAdmin.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'icons',
          name: 'Icons',
          component: () => import('@/views/pages/admin/Icons.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'social/test',
          name: 'SocialTest',
          component: () => import('@/views/pages/components/social/SocialTest.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'contractActivation',
          name: 'ContractActivation',
          component: () =>
            import('@/views/pages/management/ContractActivation.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'spamWordManagement',
          name: 'SpamWordManagement',
          component: () =>
            import('@/views/pages/management/SpamWordManagement.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'domainSummary',
          name: 'DomainSummary',
          component: () =>
            import('@/views/pages/dashboards/DomainSummary.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('@/views/pages/management/ManageUsers.vue'),
          meta: {
            permission: claims.AD_UV,
            title: 'Manage Users'
          }
        },
        {
          path: 'admin/mailingAddress',
          name: 'MailingAddressManagement',
          component: () => import('@/views/pages/admin/MailingAddressManagement.vue'),
          meta: {
            permission: claims.MMS_TM,
            title: 'Manage Mailing Address'
          }
        },
        {
          path: 'admin/domain',
          name: 'Domain Omit Management',
          component: () => import('@/views/pages/admin/DomainManagement.vue'),
          meta: {
            permission: claims.MMS_TM,
            title: 'Domain Omit Management'
          }
        },
        {
          path: 'admin/emailAddress',
          name: 'EmailAddressManagement',
          component: () => import('@/views/pages/admin/EmailAddressManagement.vue'),
          meta: {
            permission: claims.MMS_TM,
            title: 'Email Address Management'
          }
        },
        {
          path: 'admin/email-preview',
          name: 'Device Preview',
          component: () => import('@/views/pages/admin/DevicePreview.vue'),
          meta: {
            permission: claims.MMS_TM,
            title: 'Device Preview Management'
          }
        },
        {
          path: 'admin/spam-hints',
          name: 'Spam Hints Preview',
          component: () => import('@/views/pages/admin/SpamHintsAdmin.vue'),
          meta: {
            permission: claims.MMS_TM,
            title: 'Spam Hints Management'
          }
        },
        {
          path: 'userFeedbackData',
          name: 'UserFeedbackData',
          component: () =>
            import('@/views/pages/management/UserFeedbackData.vue'),
          meta: {
            permission: claims.MMS_TM
          }
        },
        {
          path: 'permissions',
          name: 'Permissions',
          component: () =>
            import('@/views/pages/management/PermissionsOverview.vue')
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/views/pages/UserProfile.vue')
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/views/pages/management/CustomerAccount.vue'),
          meta: {
            permission: claims.AD_AS
          }
        },
        {
          path: 'help/:article?',
          name: 'HelpArticle',
          props: true,
          component: () => import('@/views/pages/components/help/HelpArticle.vue'),
          meta: {
            title: 'Help Articles'
          }
        },
        {
          path: 'releaseNotes',
          name: 'ReleaseNotes',
          component: () => import('@/views/pages/components/help/ReleaseNotes.vue')
        },
        {
          path: 'noww/:nowwRoute',
          name: 'NowwRedirect',
          props: true,
          component: () => import('@/views/pages/NowwRedirect.vue'),
          meta: {
            title: 'NOWW'
          }
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/components/layout/external/ExternalIndex.vue'),
      meta: { requiresAuth: false, onlyNotAuth: true },
      children: [
        {
          path: 'login',
          name: 'AppLogin',
          meta: { onlyNotAuth: true },
          component: () => import('@/views/pages/auth/AppLogin.vue')
        },
        {
          path: 'forgotPassword',
          name: 'ForgotPassword',
          meta: { onlyNotAuth: true },
          component: () => import('@/views/pages/auth/ForgotPassword.vue')
        },
        {
          path: 'passwordReset',
          name: 'PasswordReset',
          meta: { onlyNotAuth: true },
          component: () => import('@/views/pages/auth/PasswordReset.vue'),
          props: route => ({
            code: route.query.code,
            userId: route.query.userId
          })
        }
      ]
    },
    {
      path: '/optout',
      name: 'OptOutRecipient',
      component: () => import('@/views/pages/external/OptOutRecipient.vue'),
      meta: { requiresAuth: false },
      props: route => ({
        mailingId: route.query.mailingId,
        recipientId: route.query.recipientId,
        jobId: route.query.jobId,
        email: route.query.email
      })
    },
    {
      path: '/nowwlogout',
      name: 'NowwLogout',
      component: () => import('@/views/pages/auth/NowwLogout.vue'),
      meta: {
        requiresAuth: false,
        title: 'Logout'
      }
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/pages/404.vue')
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('@/views/pages/Maintenance.vue')
    }
  ]
})

function requiresNoAuthentication (to, from, next) {
  if (store.getters['auth/isAuthenticated']() && to.meta.onlyNotAuth) {
    next({ name: 'CampaignDashboard' })
  } else if (
    store.getters['auth/getRefreshToken']()
  ) {
    // Restore base application state
    store.dispatch('auth/refreshTokens').then(() => {
      if (to.meta.onlyNotAuth) {
        next({ name: 'CampaignDashboard' })
      } else {
        next()
      }
    })
  } else {
    next()
  }
}

function requiresAuthentication (to, from, next) {
  if (!store.getters['auth/isAuthenticated']()) {
    if (
      store.getters['auth/getRefreshToken']()
    ) {
      // Need to refresh session
      store
        .dispatch('auth/refreshTokens')
        .then(() => {
          if (to.matched.some(record => record.meta.permission)) {
            requiresAuthorization(to, from, next)
          } else {
            next()
          }
        })
        .catch(() => {
          // Not Authenticated
          store.commit('setGlobalSnackbar', {
            message: DefaultUnauthenticatedMessage,
            color: 'error'
          })
          store.dispatch('auth/logOut')
          next({
            name: 'AppLogin',
            query: { redirect: to.fullPath }
          })
        })
    } else {
      // Not Authenticated
      store.commit('setGlobalSnackbar', {
        message: DefaultUnauthenticatedMessage,
        color: 'error'
      })
      store.dispatch('auth/logOut')
      next({
        name: 'AppLogin',
        query: { redirect: to.fullPath }
      })
    }
  } else if (to.matched.some(record => record.meta.permission)) {
    requiresAuthorization(to, from, next)
  } else {
    next()
  }
}

function requiresAuthorization (to, from, next) {
  if (!store.getters['auth/hasClaimKV'](to.meta.permission)) {
    if (to.meta.permissionRedirect) {
      next(to.meta.permissionRedirect)
    } else {
      // Not Authorized
      store.commit('setGlobalSnackbar', {
        message: DefaultForbiddenMessage,
        color: 'error'
      })
      next(false)
    }
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => {
  const APP_IS_MAINTENANCE = process.env.VUE_APP_IS_MAINTENANCE
  if (from.path === '/' && to.path !== '/maintenance' && (APP_IS_MAINTENANCE === 'true')) {
    router.push({ path: '/maintenance' })
  } else if (to.path !== '/maintenance') {
    next()
  }
  if (from.path === '/' && (to.name === 'NowwRedirect')) {
    router.go(-1)
    next(false)
  } else if (to.matched.some(record => record.meta.requiresAuth === true)) {
    requiresAuthentication(to, from, next)
  } else if (to.matched.some(record => record.meta.requiresAuth === false)) {
    requiresNoAuthentication(to, from, next)
  } else if (to.matched.some(record => record.meta.permission)) {
    requiresAuthorization(to, from, next)
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  // TODO: look into https://github.com/axios/axios#cancellation for cancelling active axios requests after we re-route pages
  store.commit('resetHttpReq')
  store.commit('setDisplayDrawer', true)
  store.commit('clearGLobalSnackbarOnPageChange', null)
})

export default router
