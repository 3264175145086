import client, { handleError, noLoadingConfig } from '../axios-config'

const controllerPrefix = '/pubapi'

const getPubApiUsageReport = async function (month, year, nowwId) {
  try {
    const response = await client.get(`${controllerPrefix}/${year}/${month}/${nowwId}/`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const pubapiService = {
  getPubApiUsageReport
}
