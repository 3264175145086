import colors from 'vuetify/lib/util/colors'

export const inputDateFormat = 'YYYY-MM-DD'
export const inputMonthFormat = 'YYYY-MM'
export const displayMonthFormat = 'MMM YYYY'
export const displayDateFormat = 'MM/DD/YYYY'
export const displayDateFormat2 = 'MMM DD, YYYY'
export const displayDateFormat3 = 'ddd MMM Do'
export const displayTimeFormat = 'HH:mm:ss.SSS'
export const displayTimeFormat2 = 'HH:mm'
export const displayTimeFormat3 = 'h:mm a'
export const displayDateTimeFormat = 'MM/DD/YYYY h:mm a'
export const displayDateTimeFormat2 = 'MM/DD h:mm a'
export const calendarEventFormat = 'YYYY-MM-DD HH:mm'

export const scriptOneDrive = 'https://js.live.net/v7.2/OneDrive.js'

export const purpleRedGradientDark16 = [
  { enum: colors.deepPurple.darken4, color: 'deep-purple darken-4' },
  { enum: colors.deepPurple.darken1, color: 'deep-purple darken-1' },
  { enum: colors.indigo, color: 'indigo' },
  { enum: colors.blue.darken3, color: 'blue darken-3' },
  { enum: colors.cyan.darken2, color: 'cyan darken-2' },
  { enum: colors.teal, color: 'teal' },
  { enum: colors.green.darken1, color: 'green darken-1' },
  { enum: colors.lightGreen.darken2, color: 'light-green darken-2' },
  { enum: colors.lime.darken2, color: 'lime darken-2' },
  { enum: colors.yellow.darken3, color: 'yellow darken-3' },
  { enum: colors.orange, color: 'orange' },
  { enum: colors.orange.darken2, color: 'orange darken-2' },
  { enum: colors.deepOrange, color: 'deep-orange' },
  { enum: colors.deepOrange.darken2, color: 'deep-orange darken-2' },
  { enum: colors.red.darken2, color: 'red darken-2' },
  { enum: colors.red.darken4, color: 'red darken-4' }
]

export const greenGreyGradientLight8 = [
  { enum: colors.green.lighten2, color: 'green lighten-2' },
  { enum: colors.lightGreen.lighten1, color: 'light-green lighten-1' },
  { enum: colors.lime, color: 'lime' },
  { enum: colors.yellow, color: 'yellow' },
  { enum: colors.amber.lighten2, color: 'yellow lighten-2' },
  { enum: colors.orange.lighten1, color: 'yellow lighten-4' },
  { enum: colors.deepOrange.lighten2, color: 'grey lighten-3' },
  { enum: colors.red.lighten1, color: 'grey lighten-5' }
]

export const greenRedGradientLight8 = [
  { enum: colors.green.lighten2, color: 'green lighten-2' },
  { enum: colors.lightGreen.lighten1, color: 'light-green lighten-1' },
  { enum: colors.lime, color: 'lime' },
  { enum: colors.yellow, color: 'yellow' },
  { enum: colors.amber.lighten2, color: 'amber lighten-2' },
  { enum: colors.orange.lighten1, color: 'orange lighten-1' },
  { enum: colors.deepOrange.lighten2, color: 'deep-orange lighten-2' },
  { enum: colors.red.lighten1, color: 'red lighten-1' }
]

export default {
  inputDateFormat,
  inputMonthFormat,
  displayMonthFormat,
  displayDateFormat,
  displayDateFormat2,
  displayDateFormat3,
  displayTimeFormat,
  displayTimeFormat2,
  displayDateTimeFormat,
  displayDateTimeFormat2,
  displayTimeFormat3,
  calendarEventFormat,
  purpleRedGradientDark16,
  greenGreyGradientLight8,
  greenRedGradientLight8
}
