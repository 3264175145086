import Vue from 'vue'
import router from '../router'

import VueAppInsights from 'vue-application-insights'
// https://github.com/latelierco/vue-application-insights

Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_APPINSIGHTS_KEY,
  router,
  baseName: `InsApp-${process.env.NODE_ENV}`
})
