import client, { parseItem, handleError, noLoadingConfig, formUploadConfig } from '../axios-config'
import { CreativeModel } from '@/shared/models/content-library'

const controllerPrefix = '/mailing'

const createCampaign = async function (campaign) {
  try {
    const response = await client.post(`${controllerPrefix}/createCampaign`, campaign, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addMailing = async function (campaignId) {
  try {
    const response = await client.post(`${controllerPrefix}/addMailing/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyMailing = async function (mailingItemId) {
  try {
    const response = await client.post(`${controllerPrefix}/copyMailing/${mailingItemId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyCampaign = async function (campaignId) {
  try {
    const response = await client.post(`${controllerPrefix}/copyCampaign/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const cancelCampaign = async function (campaignId) {
  try {
    const response = await client.delete(`${controllerPrefix}/cancelCampaign/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getMailing = async function (mailingItemId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/${mailingItemId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCampaign = async function (campaignId) {
  try {
    const response = await client.get(`${controllerPrefix}/campaign/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getMailingsByCampaign = async function (campaignId) {
  try {
    const response = await client.get(`${controllerPrefix}/mailings/campaign/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTA = async function (targetId) {
  try {
    const response = await client.get(`${controllerPrefix}/ta/${targetId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const createSplit = async function (split) {
  try {
    const response = await client.post(`${controllerPrefix}/split`, split)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteSplit = async function (splitId) {
  try {
    const response = await client.delete(`${controllerPrefix}/split/${splitId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteResend = async function (resendId) {
  try {
    const response = await client.delete(`${controllerPrefix}/resend/${resendId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const sentimentAnalysis = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/sentimentAnalysis`, { text: data })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const extractKeyPhrases = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/keyPhrases`, { text: data })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const splitTextAnalysis = async function (splitId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/splitTextAnalysis/${splitId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const textAnalysisLimit = async function (tenantId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/textAnalysisLimit/${tenantId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setCampaignValue = async function (campaignId, prop, value) {
  try {
    const response = await client.post(`${controllerPrefix}/campaign/${prop}`, { campaignId, value: value !== undefined && value !== null ? value.toString() : value }, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setMailingValue = async function (mailingItemId, prop, value) {
  try {
    const response = await client.post(`${controllerPrefix}/mailing/${prop}`, { mailingItemId, value: value !== undefined && value !== null ? value.toString() : value }, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const setSplitValue = async function (splitId, prop, value) {
  try {
    const response = await client.post(`${controllerPrefix}/split/${prop}`, { splitId, value: value !== undefined && value !== null ? value.toString() : value }, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCampaignDashboard = async function (req) {
  try {
    const response = await client.post(`${controllerPrefix}/campaignDashboard`, req)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCampaignDashboardItem = async function (campaignId) {
  try {
    const response = await client.get(`${controllerPrefix}/campaignDashboard/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getRequiredToScheduleId = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/requiredToScheduleId`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const checkLinks = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/checkLinks/${splitId}`, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const checkSubjectLine = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/checkSubjectLine`, data, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getAIOpenScore = async function (aiOpenRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/azureAIOpens`, aiOpenRequest, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getAIClickScore = async function (aiClickRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/azureAIClicks`, aiClickRequest, noLoadingConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const validateHtml = async function (splitId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/validateHtml/${splitId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSpamScore = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/spamScore/${splitId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const parseCreativeText = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/split/parseCreativeText/${splitId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const uploadSplitTextVersion = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/split/upload/text`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const testSplit = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/split/test/${splitId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const testMailing = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/mailing/test/${mailingItemId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSplit = async function (splitId, noLoad = false) {
  try {
    const response = await client.get(`${controllerPrefix}/split/${splitId}`, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSplitIsTested = async function (splitId) {
  try {
    const response = await client.get(`${controllerPrefix}/split/isTested/${splitId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getGlobalResendsList = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/globalResendsList/${mailingItemId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getPreviousSendSTO = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/resend/previousSendSTO/${mailingItemId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSplitSendHours = async function (mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/split/splitSendHours/${mailingItemId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOptOut = async function (optOutId) {
  try {
    const response = await client.get(`${controllerPrefix}/optOut/${optOutId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getOptOutIdAcoustic = async function (acousticMailingId) {
  try {
    const response = await client.get(`${controllerPrefix}/optOut/acoustic/${acousticMailingId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const optOutRecipient = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/optOut/recipient`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const downloadSampleContactFile = async function (targetId) {
  try {
    const response = await client.get(`${controllerPrefix}/sampleContact/download/${targetId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const uploadTAFile = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/ta/upload`, data, formUploadConfig)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const copyTA = async function (targetId) {
  try {
    const response = await client.get(`${controllerPrefix}/ta/copy/${targetId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const deleteTA = async function (targetId) {
  try {
    const response = await client.delete(`${controllerPrefix}/ta/delete/${targetId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCampaignHistory = async function (campaignId) {
  try {
    const response = await client.get(`${controllerPrefix}/campaign/history/${campaignId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getMailingResults = async function (mailingResultsRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/results`, mailingResultsRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSplitLinkResults = async function (splitLinkResultsRequest, noLoad = false) {
  try {
    const response = await client.post(`${controllerPrefix}/results/link`, splitLinkResultsRequest, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getScheduledMailings = async function (tenantCrmId, start, end, noLoad = false) {
  try {
    const response = await client.post(`${controllerPrefix}/results/scheduled`, { tenantCrmId, start, end }, noLoad ? noLoadingConfig : {})
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSentMailingsList = async function (tenantCrmId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/sentMailings/${tenantCrmId}`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSingleMailingReport = async function (mailingResultsRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/singleMailing`, mailingResultsRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCampaignReport = async function (campaignReportRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/campaign`, campaignReportRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCombinedCampaignReport = async function (campaignReportRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/campaign/combined`, campaignReportRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getTopPerformers = async function (topPerformersRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/topPerformers`, topPerformersRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getBestDayReport = async function (bestDayReportRequest) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/bestDayReport`, bestDayReportRequest)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getCompletedCampaignsList = async function (tenantCrmId, search = '', geoDataRestriction = false) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/completedCampaigns`, { tenantCrmId, search, geoDataRestriction })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const downloadSingleMailingReportCsv = async function (reportName, mailingItemId) {
  try {
    const response = await client.get(`${controllerPrefix}/reports/singleMailing/${reportName}/${mailingItemId}`, { responseType: 'blob' })
    return response
  } catch (error) {
    return handleError(error)
  }
}

const getWPScheduledMailingsDetailed = async function (start, end) {
  try {
    const response = await client.post(`${controllerPrefix}/wp/admin/scheduled`, { start, end })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getWPScheduledMailingsByResults = async function (search) {
  try {
    const response = await client.post(`${controllerPrefix}/wp/admin/scheduled/search`, search)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const scheduleProdMailing = async function (mailingItemId, sendType) {
  try {
    const response = await client.post(`${controllerPrefix}/wp/schedule`, { mailingItemId, sendType })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUSCountiesJSON = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/maps/usCounties`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUSStatesJSON = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/maps/usStates`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getGeoEventsByCounty = async function (fileType, acousticMailingIds) {
  try {
    const response = await client.post(`${controllerPrefix}/maps/usCounties/events`, { fileType, acousticMailingIds })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getServiceLineSpecialty = async function ({ customerNowwId, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/serviceLine/specialty`, { customerNowwId, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getServiceLineOfferType = async function ({ customerNowwId, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/serviceLine/offerType`, { customerNowwId, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSpecialtyEngagement = async function ({ customerNowwId, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/specialtyEngagement`, { customerNowwId, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportMetricWidget = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/metrics`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportAvgReadSecsWidget = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/avgReadSecs`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportSentWidget = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/sent`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportMailClientsWidget = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/mailClients`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportReadEnvWidget = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/readEnv`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportMetricWidgetDetails = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/metrics/details`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportAvgReadSecsWidgetDetails = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/avgReadSecs/details`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportSentWidgetDetails = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/sent/details`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportMailClientsWidgetDetails = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/mailClients/details`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getReportReadEnvWidgetDetails = async function ({ customerNowwId, metricName, startDate, endDate }) {
  try {
    const response = await client.post(`${controllerPrefix}/reports/widgets/readEnv/details`, { customerNowwId, metricName, startDate, endDate })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getSpamWordListJSON = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/spam/spamWordList`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const addSpamWordToJSON = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/spam/addSpamWordToJSON`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const removeSpamWordToJSON = async function (data) {
  try {
    const response = await client.post(`${controllerPrefix}/spam/removeSpamWordToJSON`, data)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const updateUrlShorteners = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/spam/updateUrlShorteners`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

const getUrlShortenersLastUpdatedTime = async function () {
  try {
    const response = await client.get(`${controllerPrefix}/spam/updateUrlShorteners/status`)
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

export const mailingService = {
  getUrlShortenersLastUpdatedTime,
  createCampaign,
  addMailing,
  copyMailing,
  copyCampaign,
  cancelCampaign,
  getMailing,
  getCampaign,
  getMailingsByCampaign,
  getTA,
  createSplit,
  deleteSplit,
  deleteResend,
  sentimentAnalysis,
  extractKeyPhrases,
  splitTextAnalysis,
  textAnalysisLimit,
  setCampaignValue,
  setMailingValue,
  setSplitValue,
  getCampaignDashboard,
  getCampaignDashboardItem,
  getRequiredToScheduleId,
  checkLinks,
  checkSubjectLine,
  validateHtml,
  getSpamScore,
  parseCreativeText,
  uploadSplitTextVersion,
  testSplit,
  testMailing,
  getSplit,
  getSplitIsTested,
  getGlobalResendsList,
  getPreviousSendSTO,
  getSplitSendHours,
  getOptOut,
  getOptOutIdAcoustic,
  optOutRecipient,
  downloadSampleContactFile,
  uploadTAFile,
  copyTA,
  deleteTA,
  getAIOpenScore,
  getAIClickScore,
  getCampaignHistory,
  getMailingResults,
  getSplitLinkResults,
  getScheduledMailings,
  getSentMailingsList,
  getSingleMailingReport,
  getCampaignReport,
  getCombinedCampaignReport,
  getCompletedCampaignsList,
  downloadSingleMailingReportCsv,
  getWPScheduledMailingsDetailed,
  getWPScheduledMailingsByResults,
  scheduleProdMailing,
  addSpamWordToJSON,
  removeSpamWordToJSON,
  getSpamWordListJSON,
  updateUrlShorteners,
  getUSCountiesJSON,
  getUSStatesJSON,
  getGeoEventsByCounty,
  getServiceLineSpecialty,
  getServiceLineOfferType,
  getSpecialtyEngagement,
  getTopPerformers,
  getBestDayReport,
  getReportMetricWidget,
  getReportAvgReadSecsWidget,
  getReportSentWidget,
  getReportMailClientsWidget,
  getReportReadEnvWidget,
  getReportMetricWidgetDetails,
  getReportAvgReadSecsWidgetDetails,
  getReportSentWidgetDetails,
  getReportMailClientsWidgetDetails,
  getReportReadEnvWidgetDetails
}
