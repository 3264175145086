import { render, staticRenderFns } from "./MaterialTestimony.vue?vue&type=template&id=16921262&"
import script from "./MaterialTestimony.vue?vue&type=script&lang=js&"
export * from "./MaterialTestimony.vue?vue&type=script&lang=js&"
import style0 from "./MaterialTestimony.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VCard,VCardText,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16921262')) {
      api.createRecord('16921262', component.options)
    } else {
      api.reload('16921262', component.options)
    }
    module.hot.accept("./MaterialTestimony.vue?vue&type=template&id=16921262&", function () {
      api.rerender('16921262', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/MaterialTestimony.vue"
export default component.exports