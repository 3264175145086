import router from '@/router'
import axios from 'axios'
import store from '@/store'

export const API = process.env.VUE_APP_API
export const Inscriptio = process.env.VUE_APP_INSCRIPTIO_API

export const InscriptioAPI = `${Inscriptio}${API}`
export const NOWWUrl = process.env.VUE_APP_NOWW_URL

export const DefaultErrorMessage = 'There was a problem with your request. Please try again.'
export const DefaultUnauthenticatedMessage = 'You must log in first.'
export const DefaultUnauthorizedMessage = 'Your session has expired.'
export const DefaultForbiddenMessage = 'You are not authorized for that.'

const client = axios.create({
  baseURL: InscriptioAPI
})

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
client.interceptors.request.use(
  (config) => {
    let token = store.getters['auth/getToken']()

    if (token) {
      config.headers['Authorization'] = store.getters['auth/getBearerToken']()
    }
    if (process.env.VUE_APP_IS_DEBUG === 'true') { console.info('✉️ req', config) }

    if (!config.headers['NoLoading']) store.commit('openHttpReq')
    return config
  },
  (error) => {
    if (process.env.VUE_APP_IS_DEBUG === 'true') { console.error('✉️ req', error) }
    if (!error.response.config.headers['NoLoading']) store.commit('closeHttpReq')
    return Promise.reject(error)
  }
)

// function createAxiosResponseInterceptor () {
client.interceptors.response.use(
  (response) => {
    if (process.env.VUE_APP_IS_DEBUG === 'true') { console.info('✉️ res', response) }
    if (!response.config.headers['NoLoading']) store.commit('closeHttpReq')
    return response
  },
  (error) => {
    if (error.response && error.response.config.url === '/auth/refresh') {
      return
    }
    if (error.isAxiosError && (!error.response || error.response.status !== 401)) {
      store.commit('closeHttpReq')
      if (process.env.VUE_APP_IS_DEBUG === 'true') { console.error('✉️ res AxiosError resp... rejecting', error) }
      return Promise.reject(error)
    }
    if (!error.response.config.headers['NoLoading']) store.commit('closeHttpReq')
    if (!error.response.headers['token-expired']) {
      if (process.env.VUE_APP_IS_DEBUG === 'true') { console.error(`✉️ res ${error.response.status} no "token-expired" resp... rejecting`, error) }
      if (error.response.status === 401) {
        store.dispatch('auth/logOut')
        router.push({ name: 'AppLogin', query: { redirect: router.currentRoute.path } })
      }
      return Promise.reject(error)
    }

    // client.interceptors.response.eject(interceptor)
    if (process.env.VUE_APP_IS_DEBUG === 'true') { console.info('✉️ res 401 w/ "token-expired" resp... refreshing JWT token...', error) }
    return store.dispatch('auth/refreshTokens')
      .then((token) => {
        error.response.config.__isRetryRequest = true
        return client.request(error.response.config)
      }).catch(error2 => {
        if (process.env.VUE_APP_IS_DEBUG === 'true') { console.error('✉️ res error refreshing JWT token - no valid refreshToken... logging out', error2) }
        store.dispatch('auth/logOut')
        if (!error.response.config.headers['NoLoading']) store.commit('closeHttpReq')
        store.commit('setGlobalSnackbar', { message: 'Your session has expired.', color: 'error', isPageChange: true })
        router.push({ name: 'AppLogin', query: { redirect: router.currentRoute.path } })
        Promise.reject(error2)
        return Promise.reject(error)
      }).finally(() => {
        // createAxiosResponseInterceptor()
        if (!error.response.config.headers['NoLoading']) store.commit('closeHttpReq')
      })
  }
)
// }
// createAxiosResponseInterceptor()

export default client

export const parseItem = (response, code) => {
  if (response.status !== code) throw Error(response.message)
  let item = response.data
  if (typeof item !== 'object') {
    item = undefined
  }
  return item
}

export const handleError = error => {
  if (!error.response) return { errorResponse: DefaultErrorMessage }
  console.error(error.response)
  if (error.response.status === 401) return { errorResponse: error.response.data || DefaultUnauthorizedMessage }
  if (error.response.status === 403) return { errorResponse: error.response.data || DefaultForbiddenMessage }
  return { errorResponse: error.response.data }
}

export const noLoadingConfig = {
  headers: {
    'NoLoading': true
  }
}

export const formUploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
