import colors from 'vuetify/lib/util/colors'

export default {
  primary: '#6d2c8a',
  secondary: '#3c3c3c', // '#000000',
  tertiary: '#3c3c3c', // colors.grey.darken1,
  accent: '#ff7235',
  error: '#ed1a3b',
  info: '#2b93c1',
  success: '#4bb2b4',
  warning: '#FFC107'
}
