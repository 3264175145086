import { azBlobService } from '@/shared/services'
import { CreativeModel } from '@/shared/models/content-library'

function initialState () {
  return {
    creative: new CreativeModel()
  }
}

const actions = {
  async getSelectedCreative ({ commit, dispatch }, { model }) {
    const resp = await azBlobService.getCreative(model)
    commit('setCreative', resp)
    return resp
  },
  async createCreative ({ commit, dispatch }, { model }) {
    const resp = await azBlobService.newCreative(model)
    commit('setCreative', resp)
    return resp
  },
  async saveCreative ({ commit, dispatch }, { model }) {
    const resp = await azBlobService.saveCreative(model)
    commit('setCreative', resp)
    return model
  }
}

const getters = {
  getCreative: state => state.creative
}

const mutations = {
  reset (state) { // https://github.com/vuejs/vuex/issues/1118
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  setCreative (state, data) {
    state.creative = data
  },
  clearCreative (state, data) {
    state.creative.ImagePath = ''
    state.creative.Path = ''
    state.creative.Modified = null
    state.creative.FileName = ''
    state.creative.Html = ''
    state.creative.TenantCrmId = ''
  },
  setHtml (state, data) {
    state.creative.Html = data
  }
}

export default {
  namespaced: true,
  actions,
  state: initialState(),
  getters,
  mutations
}
