import client, { handleError } from '../axios-config'

const controllerPrefix = '/emailMaster'

const searchDomainOmits = async function (domain: string) {
  try {
    const res = await client.get(`${controllerPrefix}/email/domain/search/${domain}`)
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const addDomain = async function (
  domainName: string,
  omitReason: string
) {
  try {
    const res = await client.post(`${controllerPrefix}/email/domain/add`, {
      domainName,
      omitReason
    })
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const emailAddNew = async function (originalEmail: string, newEmail: string, meNumber: string, fileType: string) {
  try {
    const res = await client.post(`${controllerPrefix}/email/addnew/${originalEmail}/${newEmail}/${meNumber}/${fileType}`)
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const emailOptOut = async function (email: string) {
  try {
    const res = await client.get(`${controllerPrefix}/email/optout/${email}`)
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const emailUnlink = async function (email: string, MeNumber: string) {
  try {
    const res = await client.get(`${controllerPrefix}/email/unlink/${email}/${MeNumber}`)
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const getEmailsByEmailAddress = async function (email: string) {
  try {
    const res = await client.get(`${controllerPrefix}/email/search/${email}`)
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

interface GetEmailsParams {
  firstName: string;
  lastName: string;
  state: string;
  zip: string;
  fileType: string;
}

const getEmailsByParams = async function (params: GetEmailsParams) {
  try {
    const res = await client.get(`${controllerPrefix}`, {
      params: {
        ...params
      }
    })
    return res.data
  } catch (err) {
    return handleError(err)
  }
}

const deleteDomain = async function (domain: string) {
  try {
    await client.delete(`${controllerPrefix}/email/domain/delete`, {
      params: {
        searchString: domain
      }
    })
  } catch (err) {
    return handleError(err)
  }
}

export const emailMasterService = {
  emailAddNew,
  emailOptOut,
  emailUnlink,
  getEmailsByEmailAddress,
  getEmailsByParams,
  searchDomainOmits,
  addDomain,
  deleteDomain
}
